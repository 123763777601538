/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Layout,
  Menu,
  Row,
  Col,
  Image,
  Space,
  Dropdown,
  Divider,
  Typography,
  Avatar,
  notification,
  Select,
  List,
  Badge,
  Checkbox,
  Form,
  Spin,
  Empty,
  Button,
  Tooltip,
} from "antd";
import { IoTime } from "react-icons/io5";
import TeraleadsLogo from "../assets/logo/TeraleadsRemoveBg.png";
import { MdBugReport, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { MdZoomOutMap } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  UserOutLineSvg,
  Appointmentssvg,
  Callsvg,
  Chatsvg,
  Dashboardsvg,
  LogoCircle,
  Reportsvg,
  SettingsSVG,
} from "../Common/SettingSidebarIconsSvg";
import axios from "axios";
import { getInitials } from "../Common/ReturnColumnValue";
import { RiPriceTagFill } from "react-icons/ri";
import { MdOutlineZoomInMap } from "react-icons/md";
import dayjs from "dayjs";
import { Content } from "antd/es/layout/layout";
import { LuLogOut } from "react-icons/lu";
import { BsArrowRepeat } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { ViewLeadDetailsShort, ViewUpdateLeadDetails } from "./Modal";
import Search from "antd/es/transfer/search";
import ReportBugs from "../Common/ReportBugs";

const Conversations = React.lazy(() => import("../Conversations"));
const QuickConversation = React.lazy(() =>
  import("../Common/QuickConversation")
);
const Appointments = React.lazy(() => import("../Appointments/index"));
const Leads = React.lazy(() => import("../Leads"));
const Settings = React.lazy(() => import("../Settings"));
const Reports = React.lazy(() => import("../Reports"));
const CallLogs = React.lazy(() => import("../CallLogs"));
const Dashboard = React.lazy(() => import("../Dashboard"));

const { Header, Sider } = Layout;
const { Option } = Select;
const CustomLayout = ({
  loginUserDetails,
  setloginUserDetails,
  selectedClinicIndex,
  setselectedClinicIndex,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const incomingRingtoneRef = useRef(new Audio("/InComingCall.mp3"));
  const [ViewUpdateLeadform] = Form.useForm();
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [sidebarkey, setsidebarkey] = useState("1");
  const [defaultValueSearch, setdefaultValueSearch] = useState("leads");
  const [searchLoader, setsearchLoader] = useState(false);
  const [leadsList, setleadsList] = useState([]);
  const [isVisibleQuickConversation, setisVisibleQuickConversation] =
    useState(false);
  const [quickConversationView, setquickConversationView] = useState(null);
  const [selectedConversationDetails, setselectedConversationDetails] =
    useState();
  const containerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loadingNotification, setloadingNotification] = useState(false);
  const [isNotificationDropdownVisible, setisNotificationDropdownVisible] =
    useState(false);
  const [notificationDetailsList, setnotificationDetailsList] = useState([]);
  const [unreadCount, setunreadCount] = useState(0);
  const [isLeadsDetailsModalVisible, setisLeadsDetailsModalVisible] =
    useState(false);
  const [selectedItemDetails, setselectedItemDetails] = useState([]);
  const [userSeletedWebsiteList, setuserSeletedWebsiteList] = useState([]);
  const [clinicUsers, setclinicUsers] = useState([]);
  const [visibleprofileMenu, setvisibleprofileMenu] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState([]);
  const [websiteAccesRoles, setwebsiteAccesRoles] = useState([]);
  const [sidebarLoader, setsidebarLoader] = useState(false);
  const [moaltrigger, setmoaltrigger] = useState(false);
  const [
    isModalVisibleViewLeadDetailsShort,
    setisModalVisibleViewLeadDetailsShort,
  ] = useState(false);
  const [isViewLeadModalEditable, setisViewLeadModalEditable] = useState(false);
  const [newAppointmentDatetime, setnewAppointmentDatetime] = useState();
  const [
    isConfirmRescheduleRequestedModal,
    setisConfirmRescheduleRequestedModal,
  ] = useState(false);

  const [isAppointmentModalVisible, setisAppointmentModalVisible] =
    useState(false);

  const [isCloseLeadsPaymentModalVisible, setisCloseLeadsPaymentModalVisible] =
    useState(false);

  const [visibleleadListDropdown, setvisibleleadListDropdown] = useState(false);

  const [visibleBugReportModal, setvisibleBugReportModal] = useState(false);

  const handleMenuClick = ({ key }) => {
    const [type, id] = key.split("-");
    if (type === "clinic") {
      const tempdata = loginUserDetails?.userClinicRoles.find(
        (c) => c?.clinic?.id === parseInt(id, 10)
      );
      setuserSeletedWebsiteList(
        tempdata?.clinic?.websites?.map(
          (website) => website?.website_user_name
        ) || []
      );
      setSelectedClinic(tempdata?.clinic);
    }
  };

  const renderMenu = () => (
    <Menu onClick={handleMenuClick}>
      <Menu.Item disabled={true} icon={<BsArrowRepeat />}>
        Switch Clinic{" "}
      </Menu.Item>
      <Divider style={{ margin: 0 }} />
      {/* Clinic Menu */}
      <Menu.SubMenu
        key="clinics"
        title={
          <Space>
            {selectedClinic?.clinic_favicon ? (
              <Image
                preview={false}
                src={selectedClinic?.clinic_favicon}
                width={20}
                height={20}
              />
            ) : (
              <Avatar shape="square" size={20} />
            )}
            <Typography>
              {selectedClinic?.clinic_name || "Unnamed Clinic"}
            </Typography>
          </Space>
        }
        style={{ padding: 5 }}
      >
        <Menu.Item disabled={true} icon={<RiPriceTagFill />}>
          My Clinic
        </Menu.Item>
        <Divider style={{ marginBottom: 5, margin: 0 }} />
        {loginUserDetails?.userClinicRoles?.map((item) =>
          selectedClinic?.id !== item?.clinic.id ? (
            <Menu.Item
              key={`clinic-${item?.clinic.id}`}
              style={{ paddingTop: 5 }}
            >
              <Space>
                {item?.clinic?.clinic_favicon ? (
                  <Image
                    preview={false}
                    src={item?.clinic?.clinic_favicon}
                    width={20}
                    height={20}
                  />
                ) : (
                  <Avatar shape="square" size={20} />
                )}
                <Typography>
                  {item?.clinic?.clinic_name || "Unnamed Clinic"}
                </Typography>{" "}
              </Space>
            </Menu.Item>
          ) : (
            <Menu.Item
              key={`clinic-${item?.clinic.id}`}
              style={{ background: "#EFEEFF", paddingTop: 5 }}
            >
              <Space>
                {item?.clinic?.clinic_favicon ? (
                  <Image
                    preview={false}
                    src={item?.clinic?.clinic_favicon}
                    width={20}
                    height={20}
                  />
                ) : (
                  <Avatar shape="square" size={20} />
                )}
                <Typography>
                  {item?.clinic?.clinic_name || "Unnamed Clinic"}
                </Typography>{" "}
                <FaCheck style={{ color: "#3900DB", display: "flex" }} />
              </Space>
            </Menu.Item>
          )
        )}
      </Menu.SubMenu>

      <Menu.Item disabled={true}>Websites</Menu.Item>
      {selectedClinic?.websites?.map((website) => (
        <Menu.Item key={`website-${website?.id}`}>
          <Space>
            <Checkbox
              checked={userSeletedWebsiteList.includes(
                website.website_user_name
              )}
              onChange={(e) =>
                handleCheckboxChange(
                  website?.website_user_name,
                  e.target.checked
                )
              }
            />
            <Avatar
              shape="square"
              size={20}
              src={
                website?.website_url && isValidUrl(website?.website_url)
                  ? getLogoUrl(website?.website_url)
                  : ""
              }
            />
            <Typography>{website?.website_url || "Unnamed Website"}</Typography>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handlesetvisibleNotificationDropdown = (visible) => {
    if (visible) {
      handleGetNotificationDetails();
    }
    setisNotificationDropdownVisible(visible);
  };

  const handlesetvisibleprofileMenu = (visible) => {
    setvisibleprofileMenu(visible);
  };

  const enterFullScreen = () => {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen();
    } else if (containerRef.current.webkitRequestFullscreen) {
      containerRef.current.webkitRequestFullscreen(); // For Safari
    } else if (containerRef.current.msRequestFullscreen) {
      containerRef.current.msRequestFullscreen(); // For IE11
    }
    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen(); // For Safari
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen(); // For IE11
    }
    setIsFullScreen(false);
  };

  const searchGlobelMenu = (
    <Menu>
      <div style={{ padding: 10 }}>
        <Space
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ padding: "0px 10px 0px 10px" }}>
            {defaultValueSearch === "leads"
              ? "Leads"
              : defaultValueSearch === "appointment"
              ? "Appointment"
              : defaultValueSearch === "conversations"
              ? "Conversations"
              : ""}
          </Typography>
          <Button
            type="link"
            onClick={() => {
              if (defaultValueSearch === "leads") {
                navigate("/leads");
              } else if (defaultValueSearch === "appointment") {
                navigate("/appointments");
              } else if (defaultValueSearch === "conversations") {
                navigate("/conversations");
              }
            }}
          >
            View All{" "}
          </Button>
        </Space>

        <Divider style={{ margin: 0 }} />
      </div>
      <div
        style={{
          // width: 300,
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        <Menu>
          {searchLoader ? (
            <Menu.Item key="loading" disabled>
              <Spin style={{ marginRight: 10 }} size="small" /> Loading...
            </Menu.Item>
          ) : (
            <>
              {leadsList?.length <= 0 ? (
                <Empty />
              ) : (
                leadsList?.map((lead) => (
                  <Menu.Item
                    style={{ height: "45px" }}
                    key={lead?.id}
                    onClick={() => {
                      setselectedItemDetails(lead);
                      setisLeadsDetailsModalVisible(true);
                      setvisibleleadListDropdown(false);
                    }}
                  >
                    <Space>
                      <Avatar
                        size={30}
                        style={{
                          backgroundColor: lead?.avatar_color,
                          fontSize: 13,
                          marginRight: 10,
                        }}
                      >
                        {lead?.first_name && lead?.last_name
                          ? getInitials(
                              lead?.first_name + " " + lead?.last_name
                            )
                          : lead?.phone_number}
                      </Avatar>
                      <div>
                        <Typography>
                          {lead?.first_name && lead?.last_name
                            ? lead?.first_name + " " + lead?.last_name
                            : lead?.phone_number}
                        </Typography>
                        <Typography className="custom-text1">
                          {lead?.phone_number}
                        </Typography>
                      </div>
                    </Space>
                  </Menu.Item>
                ))
              )}
            </>
          )}
        </Menu>
      </div>
    </Menu>
  );

  const menu = (
    <Menu>
      <div
        style={{
          padding: 10,
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #E8EBEF",
        }}
      >
        <IoIosNotificationsOutline style={{ fontSize: 18 }} />
        <Typography style={{ padding: "0px 0px 0px 5px", fontSize: 15 }}>
          Notifications
        </Typography>
      </div>

      <div
        style={{
          width: 350,
          maxHeight: "260px",
          overflowY: "auto",
          padding: "0px 0px 10px 0px ",
        }}
      >
        <List
          loading={loadingNotification}
          dataSource={notificationDetailsList}
          style={{ width: "100%" }}
          renderItem={(noti) => (
            <List.Item
              style={{
                cursor: "pointer",
                background: noti?.status === "unread" ? "#F4F7FC" : "",
              }}
            >
              {noti?.type === "Appointments" ? (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 5px 0px 5px ",
                  }}
                  onClick={() => {
                    navigate("/appointments");
                    handleMarkAllReadNotification(noti?.id);
                  }}
                >
                  <Col span={4}>
                    <Appointmentssvg
                      style={{
                        padding: 10,
                        borderRadius: 50,
                        border: "1px solid #E8EBEF",
                        background: "#fff",
                        width: 15,
                        height: 15,
                      }}
                    />
                  </Col>
                  <Col span={20}>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: noti?.message?.trim(),
                        }}
                        className="multi-line-ellipsis"
                      />
                    </Typography>
                    <Space style={{ display: "flex", alignItems: "center" }}>
                      <IoTime style={{ display: "flex", color: "#72779E" }} />
                      <Typography className="custom-text1">
                        {dayjs(noti?.created_at).format("hh:mm A")}
                      </Typography>
                    </Space>
                  </Col>
                </Row>
              ) : noti?.type === "Leads" ? (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 5px 0px 5px ",
                  }}
                  onClick={() => {
                    navigate("/leads");
                    handleMarkAllReadNotification(noti?.id);
                    setselectedItemDetails(noti?.metadata);
                    setisLeadsDetailsModalVisible(true);
                    setisNotificationDropdownVisible(false);
                  }}
                >
                  <Col span={4}>
                    <UserOutLineSvg
                      style={{
                        padding: 10,
                        borderRadius: 50,
                        border: "1px solid #E8EBEF",
                        background: "#fff",
                        width: 15,
                        height: 15,
                      }}
                      color={"#000"}
                    />
                  </Col>
                  <Col span={20}>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: noti?.message?.trim(),
                        }}
                        className="multi-line-ellipsis"
                      />
                    </Typography>
                    <Space style={{ display: "flex", alignItems: "center" }}>
                      <IoTime style={{ display: "flex", color: "#72779E" }} />
                      <Typography className="custom-text1">
                        {dayjs(noti?.created_at).format("hh:mm A")}
                      </Typography>
                    </Space>
                  </Col>
                </Row>
              ) : noti?.type === "Conversations" ? (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Col span={4}>
                    <Chatsvg
                      style={{
                        padding: 10,
                        borderRadius: 50,
                        border: "1px solid #E8EBEF",
                        width: 15,
                        height: 15,
                      }}
                    />
                  </Col>
                  <Col span={20}>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: noti?.message?.trim(),
                        }}
                        className="multi-line-ellipsis"
                      />
                    </Typography>
                    <Typography>
                      {dayjs(noti?.created_at).format("hh:mm A")}
                    </Typography>
                  </Col>
                </Row>
              ) : noti?.type === "Campaigns" ? (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Col span={4}>
                    <UserOutLineSvg
                      style={{
                        padding: 10,
                        borderRadius: 50,
                        border: "1px solid #E8EBEF",
                        width: 15,
                        height: 15,
                      }}
                      color={"#000"}
                    />
                  </Col>
                  <Col span={20}>
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: noti?.message?.trim(),
                        }}
                        className="multi-line-ellipsis"
                      />
                    </Typography>
                    <Typography>
                      {dayjs(noti?.created_at).format("hh:mm A")}
                    </Typography>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </List.Item>
          )}
        />
      </div>
    </Menu>
  );

  const profileMenu = (
    <Menu>
      <div
        style={{
          padding: "10px 10px 0px 10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography className="custom-text1" style={{ fontSize: 15 }}>
          Account
        </Typography>
      </div>
      <div
        style={{
          width: 250,
        }}
      >
        <Space style={{ padding: 10 }}>
          {loginUserDetails?.profile_picture ? (
            <Avatar size={34} src={loginUserDetails?.profile_picture} />
          ) : (
            <Avatar
              size={34}
              style={{ background: loginUserDetails?.avatar_color }}
            >
              {getInitials(loginUserDetails?.dentist_full_name)}
            </Avatar>
          )}
          <div>
            <Typography
              style={{
                fontWeight: "bold",
              }}
            >
              {loginUserDetails?.dentist_full_name}
            </Typography>

            <Typography
              className="custom-text1"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "180px",
              }}
            >
              {loginUserDetails?.email}
            </Typography>
          </div>
        </Space>
        <Divider style={{ margin: 0 }} />
        <Space
          style={{ padding: 10, cursor: "pointer" }}
          onClick={() => {
            navigate("/settings");
            setvisibleprofileMenu(false);
          }}
        >
          <div style={{ display: "flex", width: 20 }}>
            <SettingsSVG color={"#72779E"} />
          </div>
          <Typography style={{ fontWeight: 500 }}>Settings</Typography>
        </Space>
        <Divider style={{ margin: 0 }} />
        <Space
          style={{ padding: 10, cursor: "pointer" }}
          onClick={() => {
            setloginUserDetails(null);
            setvisibleprofileMenu(false);
            localStorage.clear();
            navigate("/login");
          }}
        >
          <div style={{ display: "flex", width: 20 }}>
            <LuLogOut style={{ fontSize: 18, color: "#72779E" }} />
          </div>
          <Typography style={{ fontWeight: 500 }}>Logout</Typography>
        </Space>
      </div>
    </Menu>
  );

  const sidebaritems = [
    {
      key: "Home",
      label: "Home",
      type: "group",
      children: [
        {
          key: "1",
          icon: (
            <Dashboardsvg color={sidebarkey === "1" ? "#3900DB" : "#72779E"} />
          ),
          label: (
            <span style={{ color: sidebarkey === "1" ? "#000" : "#72779E" }}>
              Dashboard
            </span>
          ),
          onClick: () => navigate("/dashboard"),
        },
        {
          key: "2",
          icon: (
            <Appointmentssvg
              color={sidebarkey === "2" ? "#3900DB" : "#72779E"}
            />
          ),
          label: (
            <span style={{ color: sidebarkey === "2" ? "#000" : "#72779E" }}>
              Appointments
            </span>
          ),
          onClick: () => navigate("/appointments"),
        },
        ...(loginUserDetails?.user_type === "admin"
          ? [
              {
                key: "3",
                icon: (
                  <Reportsvg
                    color={sidebarkey === "3" ? "#3900DB" : "#72779E"}
                  />
                ),
                label: (
                  <span
                    style={{ color: sidebarkey === "3" ? "#000" : "#72779E" }}
                  >
                    Reports
                  </span>
                ),
                onClick: () => navigate("/reports"),
              },
            ]
          : []),
      ],
    },
    {
      key: "grp",
      label: "Contacts",
      type: "group",
      children: [
        {
          key: "4",
          icon: (
            <UserOutLineSvg
              color={sidebarkey === "4" ? "#3900DB" : "#72779E"}
            />
          ),
          label: (
            <span style={{ color: sidebarkey === "4" ? "#000" : "#72779E" }}>
              Leads
            </span>
          ),
          onClick: () => navigate("/leads"),
        },
        {
          key: "5",
          icon: <Callsvg color={sidebarkey === "5" ? "#3900DB" : "#72779E"} />,
          label: (
            <span style={{ color: sidebarkey === "5" ? "#000" : "#72779E" }}>
              Calls
            </span>
          ),
          onClick: () => navigate("/calls"),
        },
        {
          key: "6",
          icon: <Chatsvg color={sidebarkey === "6" ? "#3900DB" : "#72779E"} />,
          label: (
            <span style={{ color: sidebarkey === "6" ? "#000" : "#72779E" }}>
              Conversations
            </span>
          ),
          onClick: () => navigate("/conversations"),
        },
      ],
    },
  ];

  const settingsItem = {
    key: "7",
    icon: (
      <div style={{ display: "flex", width: 20 }}>
        <SettingsSVG color={sidebarkey === "7" ? "#3900DB" : "#72779E"} />
      </div>
    ),
    label: <span className="custom-text1">Settings</span>,
    onClick: () => navigate("/settings"),
  };

  const handlesetvisibleleadListDropdown = (visible) => {
    setvisibleleadListDropdown(visible);
  };
  const isValidUrl = (value) => {
    try {
      // Attempt to create a new URL object
      new URL(value);
      return true; // If no error is thrown, the URL is valid
    } catch (_) {
      return false; // If an error is thrown, the URL is invalid
    }
  };

  const getLogoUrl = (url) => {
    const domain = new URL(url)?.hostname;
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=64`;
  };

  const handleCheckboxChange = (websiteUserName, checked) => {
    setuserSeletedWebsiteList((prev) => {
      if (checked) {
        // Add website_user_name if not already in the list
        return prev.includes(websiteUserName)
          ? prev
          : [...prev, websiteUserName];
      } else {
        // Remove website_user_name if unchecked
        return prev.filter((name) => name !== websiteUserName);
      }
    });
  };

  const openNotificationWithIcon = (type, message, description) => {
    // Handle token expiration
    if (description === "Token expired. Please log in again.") {
      navigate("/login");
      return; // Prevent showing the notification if we are redirecting
    }

    // Call the notification API
    api[type]({
      message: message,
      description: description,
      duration: 5, // Duration for the notification
    });
  };

  const handleGetNotificationDetails = async (id) => {
    const token = localStorage.getItem("authToken");
    console.log();
    if (id || loginUserDetails) {
      setloadingNotification(true);

      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/notification/${
            loginUserDetails?.id ? loginUserDetails?.id : id
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setloadingNotification(false);
          setnotificationDetailsList(res?.data?.notifications);
          setunreadCount(res?.data?.unreadCount);
        })
        .catch((err) => {
          setloadingNotification(false);
          console.log(err);
        });
    }
  };

  const handleMarkAllReadNotification = async (id) => {
    const token = localStorage.getItem("authToken");
    setloadingNotification(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/notification/mark-read/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        handleGetNotificationDetails();
      })
      .catch((err) => {
        setloadingNotification(false);
        console.log(err);
        openNotificationWithIcon(
          "error",
          "Settings",
          err?.response?.data?.message || err?.message
        );
      });
  };

  const getWebsiteAccesRoles = async (user_id, clinic_id) => {
    const token = localStorage.getItem("authToken");

    setsidebarLoader(true);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/getWebsiteAccesRoles?user_id=${user_id}&clinic_id=${clinic_id}`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        ?.then((res) => {
          setsidebarLoader(false);
          setwebsiteAccesRoles(res?.data?.websiteAccesRoles);
        })
        .catch((err) => {
          setsidebarLoader(false);
          console.log(err);
        });
    } catch (err) {}
  };

  const hasAccess = (childKey, websiteAccessRoles, userSelectedWebsiteList) => {
    return websiteAccessRoles?.some((web) => {
      return (
        userSelectedWebsiteList?.includes(web?.website?.website_user_name) &&
        ((web?.accesslevel?.appointments && childKey === "2") ||
          (web?.accesslevel?.dashboard && childKey === "1") ||
          (web?.accesslevel?.reports && childKey === "3") ||
          (web?.accesslevel?.lead && childKey === "4") ||
          (web?.accesslevel?.calls && childKey === "5") ||
          (web?.accesslevel?.conversations && childKey === "6"))
      );
    });
  };

  const searchGlobel = async (searchobj) => {
    setsearchLoader(true);
    console.log(searchobj);
    if (searchobj) {
      const token = localStorage.getItem("authToken");
      let data = {};

      const haveAccess = websiteAccesRoles?.some((roleData) => {
        const permissionGroups = roleData?.role?.permission_group?.split(",");
        return (
          roleData?.accesslevel?.lead && permissionGroups?.includes("LAT:AOAL")
        );
      });

      if (haveAccess) {
        data = {
          page: 1,
          limit: 10,
          search: searchobj || "",
          assigned_id: loginUserDetails?.id,
          websiteNames: userSeletedWebsiteList || [],
          searchType: "text",
          defaultValueSearch: defaultValueSearch,
        };
      } else {
        data = {
          page: 1,
          limit: 10,
          search: searchobj || "",
          websiteNames: userSeletedWebsiteList || [],
          searchType: "text",
          defaultValueSearch: defaultValueSearch,
        };
      }

      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/searchGlobel`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          ?.then((res) => {
            setleadsList(res?.data?.leads);
            setsearchLoader(false);
          })
          ?.catch((err) => {
            setsearchLoader(false);
            console.log(err);
          });
      } catch (err) {
        setsearchLoader(false);
        openNotificationWithIcon(
          "error",
          "Lead",
          err?.response?.data?.message || err?.message
        );
      }
    } else {
      setsearchLoader(false);
      setleadsList([]);
    }
  };

  const filteredSidebarItems = sidebaritems
    .map((item) => {
      const filteredChildren = item.children.filter((child) =>
        hasAccess(child.key, websiteAccesRoles, userSeletedWebsiteList)
      );

      return filteredChildren.length > 0
        ? { ...item, children: filteredChildren }
        : null;
    })
    .filter(Boolean);

  useEffect(() => {
    if (loginUserDetails?.id && selectedClinic) {
      getWebsiteAccesRoles(loginUserDetails?.id, selectedClinic?.id);
    }
  }, [selectedClinic]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      let tempUserData = [];

      loginUserDetails?.userClinicRoles?.forEach((clinicRole) => {
        clinicRole?.clinic?.websites?.forEach((website) => {
          website?.userWebsiteRoles?.forEach((userRole) => {
            const userId = userRole?.user?.id;
            const invitationAccepted = loginUserDetails?.userWebsiteRoles?.some(
              (item) => item?.user_id === userId && item?.invitation_accepted
            );
            const userObject = {
              ...userRole?.user,
              invitation_accepted: invitationAccepted,
              websites: [
                {
                  website_name: website?.website_user_name,
                  role_name: userRole?.role?.role_name,
                  website_id: website?.id,
                  role_id: userRole?.role?.id,
                  accesslevel: userRole?.accesslevel,
                },
              ],
            };

            const existingUserIndex = tempUserData.findIndex(
              (user) => user?.id === userId
            );

            if (existingUserIndex >= 0) {
              // If user exists, update their websites array and invitation status
              const existingUser = tempUserData[existingUserIndex];
              existingUser.websites.push({
                website_name: website?.website_user_name,
                role_name: userRole?.role?.role_name,
                website_id: website?.id,
                role_id: userRole?.role?.id,
                accesslevel: userRole?.accesslevel,
              });

              // Update invitation_accepted to ensure it's up-to-date
              existingUser.invitation_accepted = invitationAccepted;
            } else {
              // If user doesn't exist, add the new user object
              tempUserData.push(userObject);
            }
          });
        });
      });

      setSelectedClinic(
        loginUserDetails?.userClinicRoles?.[selectedClinicIndex]?.clinic
      );

      setclinicUsers(tempUserData || []);

      await getWebsiteAccesRoles(
        loginUserDetails?.id,
        loginUserDetails?.userClinicRoles?.[selectedClinicIndex]?.clinic?.id
      );

      await handleGetNotificationDetails(loginUserDetails?.id);

      setuserSeletedWebsiteList(
        loginUserDetails?.userClinicRoles?.[
          selectedClinicIndex
        ]?.clinic?.websites?.map((website) => website?.website_user_name) || []
      );
    };
    if (loginUserDetails) {
      fetchAndSetData();
    }
  }, [loginUserDetails]);

  useEffect(() => {
    if (location.pathname === "/leads") {
      setsidebarkey("4");
    } else if (location.pathname === "/appointments") {
      setsidebarkey("2");
    } else if (location.pathname === "/reports") {
      setsidebarkey("3");
    } else if (location.pathname === "/conversations") {
      setsidebarkey("6");
    } else if (location.pathname === "/settings") {
      setsidebarkey("7");
    } else if (location.pathname === "/calls") {
      setsidebarkey("5");
    } else if (location.pathname === "/dashboard") {
      setsidebarkey("1");
    }
  }, [location]);

  return (
    <>
      <Layout ref={containerRef}>
        {contextHolder}
        <Sider
          width={230}
          style={{
            background:
              "linear-gradient(180deg, #FFFBFE 0%, #FEF7FE 51.48%, #F5F8FF 100%)",
            borderRight: "1px solid #E8EBEF",
          }}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div
            style={{
              padding: "10px",
              height: 65,
              color: "white",
              display: collapsed ? "flex" : "",
              alignItems: collapsed ? "center" : "",
              justifyContent: collapsed ? "center" : "",
            }}
          >
            {collapsed ? (
              <LogoCircle style={{ width: 25 }} />
            ) : (
              <Image
                preview={false}
                style={{ margin: 10 }}
                width={100}
                src={TeraleadsLogo}
              />
            )}
          </div>
          <Divider style={{ margin: 0, background: "#E8EBEF" }} />
          <div
            style={{ display: "flex", flexDirection: "column", height: "90%" }}
          >
            <Menu
              className="sidebar"
              style={{ padding: 10, background: "none", flexGrow: 1 }}
              items={filteredSidebarItems.map((item) => ({
                key: item.key,
                label: item.label,
                type: item.type,
                children: item.children.map((child) => ({
                  key: child.key,
                  icon: (
                    <div style={{ display: "flex", width: 20 }}>
                      {child.icon}
                    </div>
                  ),
                  label: child.label,
                  onClick: child.onClick,
                })),
              }))}
              loading={sidebarLoader}
            />

            {/* <Menu
              onClick={onClick}
              selectedKeys={[sidebarkey]}
              mode="inline"
              items={sidebaritems}
              style={{ padding: 10, background: "none", flexGrow: 1 }}
            /> */}

            <Menu
              mode="inline"
              style={{ padding: 10, background: "none" }}
              items={[
                {
                  key: settingsItem.key,
                  icon: settingsItem.icon,
                  label: settingsItem.label,
                  onClick: settingsItem.onClick,
                },
              ]}
            />
          </div>
        </Sider>

        <Layout style={{ minHeight: "100vh" }}>
          <Header
            className="site-layout-sub-header-background"
            style={{
              padding: 0,
              background:
                "linear-gradient(90deg, #FFFBFE 0%, #FEF7FE 51.48%, #F5F8FF 100%)",
              borderBottom: "1px solid #E8EBEF",
            }}
          >
            <Row
              style={{
                borderLeft: "1px solid #E8EBEF",
                height: 64,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col
                span={6}
                style={{ display: "flex", alignItems: "center", padding: 10 }}
              >
                <Dropdown overlay={renderMenu()} trigger={["click"]}>
                  <Space
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {selectedClinic?.clinic_favicon ? (
                      <Image
                        preview={false}
                        src={selectedClinic?.clinic_favicon}
                        width={40}
                        height={40}
                      />
                    ) : (
                      <Avatar shape="square" size={20} />
                    )}
                    <Typography style={{ fontSize: 16, fontWeight: 500 }}>
                      {selectedClinic?.clinic_name || "Unnamed Clinic"}
                    </Typography>
                    <IoIosArrowDown
                      className="custom-text1"
                      style={{ fontSize: 16, fontWeight: 500, display: "flex" }}
                    />
                  </Space>
                </Dropdown>
              </Col>
              <Col
                span={11}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <Dropdown
                  overlay={searchGlobelMenu}
                  trigger={["click"]}
                  placement="bottomCenter"
                  visible={visibleleadListDropdown}
                  onVisibleChange={handlesetvisibleleadListDropdown}
                >
                  <Search
                    placeholder="Search lead"
                    onChange={(e) => {
                      setvisibleleadListDropdown(true);
                      searchGlobel(e?.target?.value);
                    }}
                    className="custom-globel-search"
                    size="small"
                    style={{
                      padding: 20,
                      borderRadius: "0px",
                      width: "100%",
                    }}
                  />
                </Dropdown>

                <Select
                  className="custom-selector-search-type"
                  style={{ width: 140 }}
                  placeholder="Search for"
                  value={defaultValueSearch?.toString()}
                  onSelect={(e) => {
                    setdefaultValueSearch(e);
                  }}
                >
                  <Option value="leads">Leads</Option>
                  <Option value="appointment">Appointment</Option>
                  <Option value="conversations">Conversations</Option>
                </Select>
              </Col>
              <Col
                span={7}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  padding: 10,
                }}
              >
                <Space
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "end",
                    justifyItems: "center",
                  }}
                >
                  <Tooltip title={"Report Bug"}>
                    <MdBugReport
                      onClick={() => {
                        setvisibleBugReportModal(true)
                      }}
                      style={{
                        fontSize: 22,
                        display: "flex",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>

                  {/* <FiPlusCircle style={{ fontSize: 22, display: "flex" }} /> */}
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomCenter"
                    visible={isNotificationDropdownVisible}
                    onVisibleChange={handlesetvisibleNotificationDropdown}
                  >
                    {unreadCount <= 0 ? (
                      <IoIosNotificationsOutline
                        onClick={() => {
                          setisNotificationDropdownVisible(true);
                        }}
                        style={{
                          fontSize: 25,
                          display: "flex",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0px 5px 0px 5px",
                        }}
                      >
                        <Badge count={unreadCount} color="#3900DB">
                          <IoIosNotificationsOutline
                            onClick={() => {
                              setisNotificationDropdownVisible(true);
                            }}
                            style={{
                              fontSize: 25,
                              display: "flex",
                              cursor: "pointer",
                            }}
                          />
                        </Badge>
                      </div>
                    )}
                  </Dropdown>
                  {isFullScreen ? (
                    <MdOutlineZoomInMap
                      className="custom-text1"
                      onClick={exitFullScreen}
                      style={{
                        fontSize: 22,
                        display: "flex",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <MdZoomOutMap
                      className="custom-text1"
                      onClick={enterFullScreen}
                      style={{
                        fontSize: 22,
                        display: "flex",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  <Dropdown
                    overlay={profileMenu}
                    trigger={["click"]}
                    placement="bottomLeft"
                    visible={visibleprofileMenu}
                    onVisibleChange={handlesetvisibleprofileMenu}
                  >
                    <Space style={{ cursor: "pointer" }}>
                      {loginUserDetails?.profile_picture ? (
                        <Avatar
                          size={34}
                          src={loginUserDetails?.profile_picture}
                        />
                      ) : (
                        <Avatar
                          size={34}
                          style={{ background: loginUserDetails?.avatar_color }}
                        >
                          {getInitials(loginUserDetails?.dentist_full_name)}
                        </Avatar>
                      )}

                      <Typography
                        style={{
                          fontWeight: "bold",
                          maxWidth: 100,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {loginUserDetails?.dentist_full_name}
                      </Typography>
                      <MdOutlineKeyboardArrowDown
                        style={{ fontSize: 20, display: "flex" }}
                      />
                    </Space>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          </Header>
          <Content>
            {sidebarkey === "1" ? (
              <Dashboard
                openNotificationWithIcon={openNotificationWithIcon}
                loginUserDetails={loginUserDetails}
                setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
                userSeletedWebsiteList={userSeletedWebsiteList}
                websiteAccesRoles={websiteAccesRoles}
                setisModalVisibleViewLeadDetailsShort={
                  setisModalVisibleViewLeadDetailsShort
                }
                moaltrigger={moaltrigger}
                setmoaltrigger={setmoaltrigger}
              />
            ) : sidebarkey === "2" ? (
              <Appointments
                openNotificationWithIcon={openNotificationWithIcon}
                setisVisibleQuickConversation={setisVisibleQuickConversation}
                setquickConversationView={setquickConversationView}
                setselectedConversationDetails={setselectedConversationDetails}
                loginUserDetails={loginUserDetails}
                userSeletedWebsiteList={userSeletedWebsiteList}
                websiteAccesRoles={websiteAccesRoles}
                setisModalVisibleViewLeadDetailsShort={
                  setisModalVisibleViewLeadDetailsShort
                }
                selectedItemDetails={selectedItemDetails}
                setselectedItemDetails={setselectedItemDetails}
                isConfirmRescheduleRequestedModal={
                  isConfirmRescheduleRequestedModal
                }
                setisConfirmRescheduleRequestedModal={
                  setisConfirmRescheduleRequestedModal
                }
                newAppointmentDatetime={newAppointmentDatetime}
                setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
                moaltrigger={moaltrigger}
                setmoaltrigger={setmoaltrigger}
              />
            ) : sidebarkey === "3" ? (
              <Reports
                openNotificationWithIcon={openNotificationWithIcon}
                loginUserDetails={loginUserDetails}
                setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
                setselectedItemDetails={setselectedItemDetails}
                moaltrigger={moaltrigger}
                setmoaltrigger={setmoaltrigger}
              />
            ) : sidebarkey === "4" ? (
              <Leads
                openNotificationWithIcon={openNotificationWithIcon}
                isLeadsDetailsModalVisible={isLeadsDetailsModalVisible}
                setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
                selectedItemDetails={selectedItemDetails}
                setselectedItemDetails={setselectedItemDetails}
                setisVisibleQuickConversation={setisVisibleQuickConversation}
                setquickConversationView={setquickConversationView}
                setselectedConversationDetails={setselectedConversationDetails}
                loginUserDetails={loginUserDetails}
                userSeletedWebsiteList={userSeletedWebsiteList}
                clinicUsers={clinicUsers}
                selectedClinic={selectedClinic}
                websiteAccesRoles={websiteAccesRoles}
                isAppointmentModalVisible={isAppointmentModalVisible}
                setisAppointmentModalVisible={setisAppointmentModalVisible}
                isCloseLeadsPaymentModalVisible={
                  isCloseLeadsPaymentModalVisible
                }
                setisCloseLeadsPaymentModalVisible={
                  setisCloseLeadsPaymentModalVisible
                }
                isViewLeadModalEditable={isViewLeadModalEditable}
                setisViewLeadModalEditable={setisViewLeadModalEditable}
                moaltrigger={moaltrigger}
                setmoaltrigger={setmoaltrigger}
              />
            ) : sidebarkey === "5" ? (
              <CallLogs
                openNotificationWithIcon={openNotificationWithIcon}
                loginUserDetails={loginUserDetails}
                setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
                userSeletedWebsiteList={userSeletedWebsiteList}
                setisVisibleQuickConversation={setisVisibleQuickConversation}
                setquickConversationView={setquickConversationView}
                setselectedConversationDetails={setselectedConversationDetails}
                websiteAccesRoles={websiteAccesRoles}
                setselectedItemDetails={setselectedItemDetails}
                selectedClinic={selectedClinic}
                moaltrigger={moaltrigger}
                setmoaltrigger={setmoaltrigger}
              />
            ) : sidebarkey === "6" ? (
              <Conversations
                openNotificationWithIcon={openNotificationWithIcon}
                loginUserDetails={loginUserDetails}
                setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
                setselectedItemDetails={setselectedItemDetails}
                websiteAccesRoles={websiteAccesRoles}
                setisVisibleQuickConversation={setisVisibleQuickConversation}
                setquickConversationView={setquickConversationView}
                setselectedConversationDetails={setselectedConversationDetails}
                userSeletedWebsiteList={userSeletedWebsiteList}
                moaltrigger={moaltrigger}
                setmoaltrigger={setmoaltrigger}
              />
            ) : sidebarkey === "7" ? (
              <Settings
                openNotificationWithIcon={openNotificationWithIcon}
                loginUserDetails={loginUserDetails}
                setloginUserDetails={setloginUserDetails}
                websiteAccesRoles={websiteAccesRoles}
                moaltrigger={moaltrigger}
                setmoaltrigger={setmoaltrigger}
              />
            ) : (
              ""
            )}

            <QuickConversation
              openNotificationWithIcon={openNotificationWithIcon}
              selectedConversationDetails={selectedConversationDetails}
              setselectedConversationDetails={setselectedConversationDetails}
              isVisibleQuickConversation={isVisibleQuickConversation}
              setisVisibleQuickConversation={setisVisibleQuickConversation}
              quickConversationView={quickConversationView}
              setquickConversationView={setquickConversationView}
              loginUserDetails={loginUserDetails}
              userSeletedWebsiteList={userSeletedWebsiteList}
              websiteAccesRoles={websiteAccesRoles}
              selectedClinic={selectedClinic}
              incomingRingtoneRef={incomingRingtoneRef}
              moaltrigger={moaltrigger}
            />
          </Content>

          <ViewLeadDetailsShort
            isModalVisibleViewLeadDetailsShort={
              isModalVisibleViewLeadDetailsShort
            }
            setisModalVisibleViewLeadDetailsShort={
              setisModalVisibleViewLeadDetailsShort
            }
            selectedItemDetails={selectedItemDetails}
            setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
            setisViewLeadModalEditable={setisViewLeadModalEditable}
            setisVisibleQuickConversation={setisVisibleQuickConversation}
            setquickConversationView={setquickConversationView}
            setselectedConversationDetails={setselectedConversationDetails}
            websiteAccesRoles={websiteAccesRoles}
            setnewAppointmentDatetime={setnewAppointmentDatetime}
            newAppointmentDatetime={newAppointmentDatetime}
            setisConfirmRescheduleRequestedModal={
              setisConfirmRescheduleRequestedModal
            }
            openNotificationWithIcon={openNotificationWithIcon}
            loginUserDetails={loginUserDetails}
          />

          <ViewUpdateLeadDetails
            openNotificationWithIcon={openNotificationWithIcon}
            selectedItemDetails={selectedItemDetails}
            setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
            setisViewLeadModalEditable={setisViewLeadModalEditable}
            buttonLoader={buttonLoader}
            setbuttonLoader={setbuttonLoader}
            isLeadsDetailsModalVisible={isLeadsDetailsModalVisible}
            isViewLeadModalEditable={isViewLeadModalEditable}
            setisAppointmentModalVisible={setisAppointmentModalVisible}
            ViewUpdateLeadform={ViewUpdateLeadform}
            setisCloseLeadsPaymentModalVisible={
              setisCloseLeadsPaymentModalVisible
            }
            setisVisibleQuickConversation={setisVisibleQuickConversation}
            setquickConversationView={setquickConversationView}
            setselectedConversationDetails={setselectedConversationDetails}
            websiteAccesRoles={websiteAccesRoles}
            selectedClinic={selectedClinic}
            setmoaltrigger={setmoaltrigger}
            loginUserDetails={loginUserDetails}
          />
          
          <ReportBugs
            visibleBugReportModal={visibleBugReportModal}
            setvisibleBugReportModal={setvisibleBugReportModal}
            openNotificationWithIcon={openNotificationWithIcon}
          />
        </Layout>
      </Layout>
    </>
  );
};

export default CustomLayout;
