/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Card, Row, Col, List, Space, Typography, Button } from "antd";
import React from "react";
import { getInitials } from "./ReturnColumnValue";
import { IoArrowForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const ClinicSwitch = ({ loginUserDetails, setselectedClinicIndex }) => {
  const navigate = useNavigate();

  
  return (
    <>
      <div className="login-container-left">
        <div style={{ maxWidth: "500px", margin: "auto", padding: "10px" }}>
          {loginUserDetails?.profile_picture ? (
            <Avatar
              size={40}
              style={{
                margin: "10px auto",
                display: "block",
                backgroundColor: "#f0f0f0",
              }}
              src={loginUserDetails?.profile_picture}
            ></Avatar>
          ) : (
            <Avatar
              size={40}
              style={{
                backgroundColor: loginUserDetails?.avatar_color,
              }}
            >
              {getInitials(loginUserDetails?.dentist_full_name)}
            </Avatar>
          )}

          <Typography style={{ fontSize: 18, padding: "5px 0px" }}>
            Hi ,{loginUserDetails?.dentist_full_name}
          </Typography>
          <Typography className="custom-text1" style={{paddingBottom:10}}>
            Choose the clinic you want to access. You can switch between clinic
            anytime
          </Typography>

          <List
          
            itemLayout="horizontal"
            dataSource={loginUserDetails?.userClinicRoles || []}
            renderItem={(item, index) => (
              <List.Item
              onClick={() => {
                setselectedClinicIndex(index);
                navigate("/leads");
              }}
                style={{
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  backgroundColor: "#F8FAFF",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  cursor:'pointer'
                }}
              >
                <Card.Meta
                  style={{ width: "100%" }}
                  description={
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "5px 20px",
                      }}
                    >
                      <Col span={19}>
                        <Space>
                          <Avatar
                            shape="square"
                            size={40}
                            style={{
                              margin: "10px auto",
                              display: "block",
                              backgroundColor: "#f0f0f0",
                            }}
                            src={item?.clinic?.clinic_favicon}
                          />
                          <strong style={{ color: "#000" }}>
                            {item?.clinic?.clinic_name}
                          </strong>
                        </Space>
                      </Col>
                      <Col span={5}>
                        <Button
                          style={{ color: "#72779E" }}
                          type="link"
                          iconPosition={"end"}
                          icon={<IoArrowForward />}
                         
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ClinicSwitch;
