import React, { useState } from "react";
import {
  Form,
  Input,
  Upload,
  Button,
  message,
  Modal,
  Typography,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { IoChevronBackSharp } from "react-icons/io5";

const ReportBugs = ({
  visibleBugReportModal,
  setvisibleBugReportModal,
  openNotificationWithIcon,
}) => {
  const [bugReportForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [submitloader, setsubmitloader] = useState(false);

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleSubmit = async (values) => {
    setsubmitloader(true);
    const formData = new FormData();
    formData.append("summary", values.summary);
    formData.append("description", values.description);

    fileList.forEach((file) => {
      if (file.originFileObj) {
        formData.append("images", file.originFileObj, file.name);
      }
    });

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/createJiraIssue`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        ?.then((res) => {
          openNotificationWithIcon(
            "success",
            "Bug reported",
            `Bug reported successfully! Issue Key: ${res.data.issueKey}`
          );
          bugReportForm.resetFields();
          setFileList([]);
          setvisibleBugReportModal(false);
          setsubmitloader(false);
        })
        ?.catch((err) => {
          openNotificationWithIcon(
            "error",
            "Bug reported",
            "Failed to report bug. Please try again."
          );
          setsubmitloader(false);
        });
    } catch (error) {
      console.error("Error reporting bug:", error.response?.data || error);
      openNotificationWithIcon(
        "error",
        "Bug reported",
        "Failed to report bug. Please try again."
      );
      setsubmitloader(false);
    }
  };

  return (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => {
              setvisibleBugReportModal(false);
              bugReportForm?.resetFields();
              setFileList([]);
            }}
            icon={<IoChevronBackSharp />}
          />
          <Typography style={{ marginLeft: 10 }}>Report a Bug</Typography>
        </div>
      }
      visible={visibleBugReportModal}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0px 10px 10px 0px",
          }}
        >
          <Space>
            <Button
              onClick={() => {
                setvisibleBugReportModal(false);
                bugReportForm?.resetFields();
                setFileList([]);
              }}
            >
              Cancel
            </Button>
            <Button
            type="primary"
              onClick={() => {
                bugReportForm?.submit();
              }}
              loading={submitloader}
            >
              Submit
            </Button>
          </Space>
        </div>
      }
      closable={false}
      width={450}
      className="custom-modal"
    >
      <Form form={bugReportForm} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="summary"
          label="Summary"
          rules={[{ required: true, message: "Please enter a summary!" }]}
        >
          <Input placeholder="Enter bug summary" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please enter a description!" }]}
        >
          <Input.TextArea rows={4} placeholder="Describe the issue..." />
        </Form.Item>

        <Form.Item label="Attach Images">
          <Upload
            fileList={fileList}
            beforeUpload={() => false}
            onChange={handleUpload}
            multiple
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Upload Images</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReportBugs;
