/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import { GoScreenFull } from "react-icons/go";
import { IoChevronBackSharp } from "react-icons/io5";
import { getInitials } from "../Common/ReturnColumnValue";
import { FaCheck, FaRegCalendar } from "react-icons/fa";
import dayjs from "dayjs";
import {
  FileAddOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { BiEdit } from "react-icons/bi";
import TabPane from "antd/es/tabs/TabPane";
import {
  MdOutlineCancel,
  MdOutlineError,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import {
  AICallsSVG,
  AppointmentsSVG,
  ConversationalAISVG,
  Moneybagsvg,
  Paymentsvg,
  UserOutLineSvg,
} from "../Common/SettingSidebarIconsSvg";
import { FaClock } from "react-icons/fa";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { leadStatusColorAndTextList } from "../Common/CommonCodeList";
import { TiUser } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import { IoMdMic } from "react-icons/io";
import { ClinicUserOptionsList } from "../Common/CommmonComponent";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import Notes from "../Leads/Notes";
import parsePhoneNumberFromString, {
  parsePhoneNumber,
} from "libphonenumber-js";
const { Text, Title } = Typography;

const { Option } = Select;

const leadsStatusList = [
  {
    label: "All Leads",
    value: "AllLeads",
  },
  {
    label: "Appointment",
    value: "Appointment",
  },
  {
    label: "Reschedule Requested",
    value: "RescheduleRequested",
  },

  {
    label: "No Show",
    value: "NoShow",
  },
  {
    label: "No Money",
    value: "NoMoney",
  },
  {
    label: "Undecided",
    value: "Undecided",
  },
  {
    label: "Lost",
    value: "Lost",
  },
  {
    lable: "Closed",
    value: "Closed",
  },
  {
    label: "Live Agent",
    value: "LiveAgent",
  },
];

const creditScoreList = [
  {
    label: "Below 549",
    value: "Below 549",
  },
  {
    label: "550-599",
    value: "550-599",
  },
  {
    label: "600-649",
    value: "600-649",
  },
  {
    label: "650-699",
    value: "650-699",
  },
  {
    label: "Above 700",
    value: "Above 700",
  },
];

export const ViewLeadDetailsShort = ({
  isModalVisibleViewLeadDetailsShort,
  setisModalVisibleViewLeadDetailsShort,
  selectedItemDetails,
  setisLeadsDetailsModalVisible,
  setisViewLeadModalEditable,
  setisVisibleQuickConversation,
  setquickConversationView,
  setselectedConversationDetails,
  websiteAccesRoles,
  setnewAppointmentDatetime,
  newAppointmentDatetime,
  setisConfirmRescheduleRequestedModal,
  openNotificationWithIcon,
}) => {
  const handleCancel = () => {
    setisModalVisibleViewLeadDetailsShort(false);
  };

  const haveAccess = websiteAccesRoles?.some((roleData) => {
    const permissionGroups = roleData?.role?.permission_group?.split(",");
    return roleData?.accesslevel?.lead && permissionGroups?.includes("AAT:RA");
  });

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={handleCancel}
                icon={<IoChevronBackSharp />}
              ></Button>
              <Typography style={{ marginLeft: 10 }}>Appointment</Typography>
            </div>

            <div>
              <GoScreenFull
                style={{ fontSize: 25, cursor: "pointer" }}
                onClick={() => {
                  setisLeadsDetailsModalVisible(true);
                  setisModalVisibleViewLeadDetailsShort(false);
                }}
              />
            </div>
          </div>

          <Divider style={{ margin: "16px 0" }} />
          <Row gutter={[2, 16]}>
            <Col span={4}>
              <Avatar
                size={45}
                style={{
                  backgroundColor: selectedItemDetails?.avatar_color,
                  fontSize: "24px",
                }}
              >
                {selectedItemDetails?.first_name &&
                selectedItemDetails?.last_name
                  ? getInitials(
                      selectedItemDetails?.first_name +
                        " " +
                        selectedItemDetails?.last_name
                    )
                  : "-"}
              </Avatar>
            </Col>
            <Col span={18}>
              <Text
                style={{
                  color:
                    selectedItemDetails?.appointment_status ===
                      "Not Confirmed" ||
                    selectedItemDetails?.appointment_status === "Cancelled"
                      ? "red"
                      : "#52c41a",
                  fontWeight: "bold",
                }}
              >
                {selectedItemDetails?.appointment_status
                  ? selectedItemDetails?.appointment_status
                  : "-"}
              </Text>
              <Title level={4} style={{ margin: 0 }}>
                {selectedItemDetails?.last_name
                  ? selectedItemDetails?.first_name +
                    " " +
                    selectedItemDetails?.last_name
                  : "-"}
              </Title>
            </Col>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Space size="middle">
                <Button
                  onClick={() => {
                    setisModalVisibleViewLeadDetailsShort(false);
                    setisVisibleQuickConversation(true);
                    setquickConversationView("call");
                    setselectedConversationDetails(selectedItemDetails);
                  }}
                  icon={<PhoneOutlined />}
                >
                  Call
                </Button>
                <Button
                  onClick={() => {
                    setisModalVisibleViewLeadDetailsShort(false);
                    setisVisibleQuickConversation(true);
                    setquickConversationView("sms");
                    setselectedConversationDetails(selectedItemDetails);
                  }}
                  icon={<MessageOutlined />}
                >
                  SMS
                </Button>
                <Button
                  onClick={() => {
                    setisModalVisibleViewLeadDetailsShort(false);
                    setisVisibleQuickConversation(true);
                    setquickConversationView("email");
                    setselectedConversationDetails(selectedItemDetails);
                  }}
                  icon={<MailOutlined />}
                >
                  Email
                </Button>
                {websiteAccesRoles?.some((roleData) => {
                  const permissionGroups =
                    roleData?.role?.permission_group?.split(",");
                  return (
                    roleData?.accesslevel?.lead &&
                    permissionGroups?.includes("LAT:ULD")
                  );
                }) ? (
                  <Button
                    icon={<BiEdit style={{ fontSize: 16 }} />}
                    style={{ background: "#fff" }}
                    onClick={() => {
                      setisViewLeadModalEditable(true);
                      setisLeadsDetailsModalVisible(true);
                      setisModalVisibleViewLeadDetailsShort(false);
                    }}
                  >
                    Edit
                  </Button>
                ) : (
                  ""
                )}
              </Space>
            </Row>
          </Row>
        </>
      }
      visible={isModalVisibleViewLeadDetailsShort}
      footer={null}
      closable={false}
      width={400}
      className="custom-modal-lead-details "
    >
      {selectedItemDetails?.lead_status === "RescheduleRequested" &&
      haveAccess ? (
        <>
          <Row style={{ padding: 10, background: "#F4F5FC", borderRadius: 5 }}>
            <Space>
              <FaRegCalendar style={{ display: "flex" }} />
              <Typography>
                {selectedItemDetails?.first_name +
                  " " +
                  selectedItemDetails?.last_name}{" "}
                requested to reschedule.
              </Typography>
            </Space>
            <Space>
              <DatePicker
                style={{ width: "100%" }}
                className="custom-text1"
                showTime
                format="YYYY-MMM-DD hh:mm A"
                onChange={(e) => {
                  console?.log(e);
                  setnewAppointmentDatetime(e);
                }}
                // disabledDate={disabledDate}
              />
              <Button
                icon={<FaCheck style={{ color: "#3900DB", width: "100%" }} />}
              >
                <Typography
                  style={{ color: "#3900DB" }}
                  onClick={() => {
                    if (!newAppointmentDatetime) {
                      openNotificationWithIcon(
                        "error",
                        "Appointment",
                        "Please select date and time"
                      );
                    } else {
                      setisConfirmRescheduleRequestedModal(true);
                    }
                  }}
                >
                  Save
                </Typography>
              </Button>
            </Space>
          </Row>
          <Divider />
        </>
      ) : (
        ""
      )}

      <Row>
        <Col span={12}>
          <Text strong className="custom-text1">
            Time
          </Text>
        </Col>
        <Col span={12}>
          <Text strong className="custom-text1">
            Date
          </Text>
        </Col>
        <Col span={12}>
          <Text>
            {selectedItemDetails?.appointment_date_time
              ? dayjs(selectedItemDetails?.appointment_date_time).format(
                  "hh:mm A"
                )
              : "-"}
          </Text>
        </Col>
        <Col span={12}>
          <Text>
            {selectedItemDetails?.appointment_date_time
              ? dayjs(selectedItemDetails?.appointment_date_time).format(
                  "MMM DD,YYYY"
                )
              : "-"}
          </Text>
        </Col>
      </Row>
      <Divider style={{ margin: "16px 0" }} />
      <Row>
        <Col span={12}>
          <Text strong className="custom-text1">
            Assigned
          </Text>
        </Col>
        <Col span={12}>
          <Text strong className="custom-text1">
            Duration
          </Text>
        </Col>
        <Col span={12}>
          <Text>
            {" "}
            {selectedItemDetails?.assign_to
              ? selectedItemDetails?.assign_to
              : "-"}
          </Text>
        </Col>
        <Col span={12}>
          <Text>
            {selectedItemDetails?.appointment_duration
              ? selectedItemDetails?.appointment_duration
              : "-"}
            min
          </Text>
        </Col>
      </Row>

      <Divider style={{ margin: "16px 0" }} />

      {/* Appointment Note Section */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Text strong className="custom-text1">
            Appointment Note:
          </Text>
          <p style={{ marginTop: 8 }}>
            {selectedItemDetails?.appointment_notes
              ? selectedItemDetails?.appointment_notes
              : "-"}
          </p>
        </Col>
      </Row>
    </Modal>
  );
};

export const ViewUpdateLeadDetails = ({
  openNotificationWithIcon,
  selectedItemDetails,
  setisLeadsDetailsModalVisible,
  setisViewLeadModalEditable,
  buttonLoader,
  isLeadsDetailsModalVisible,
  isViewLeadModalEditable,
  setbuttonLoader,
  setisAppointmentModalVisible,
  ViewUpdateLeadform,
  setisCloseLeadsPaymentModalVisible,
  setisVisibleQuickConversation,
  setquickConversationView,
  setselectedConversationDetails,
  clinicUsers,
  websiteAccesRoles,
  setmoaltrigger,
  loginUserDetails,
}) => {
  const [activeKey, setActiveKey] = useState("1");
  const [treatmentOptions, settreatmentOptions] = useState([]);
  const [visiblelUserAssignedDropdown, setvisiblelUserAssignedDropdown] =
    useState();
  const [selectedCountryPhoneNumber, setselectedCountryPhoneNumber] =
    useState();
  const [countries, setCountries] = useState([]);
  const haveAppointmentEditAccess = websiteAccesRoles?.some((roleData) => {
    const permissionGroups = roleData?.role?.permission_group?.split(",");
    return roleData?.accesslevel?.lead && permissionGroups?.includes("AAT:RA");
  });

  const [leadActivityDetails, setleadActivityDetails] = useState([]);

  const handleCountryChange = (value) => {
    const [code, dialCode] = value.split("|");
    setselectedCountryPhoneNumber({ code, dialCode });
  };

  const handleValidation = (dialCode, phoneNumber) => {
    const fullNumber = `${dialCode}${phoneNumber}`;
    const phoneNumberObject = parsePhoneNumberFromString(
      fullNumber,
      selectedCountryPhoneNumber?.code
    );

    if (phoneNumberObject && !phoneNumberObject?.isValid()) {
      ViewUpdateLeadform.resetFields(["phone_number"]);
      message.error("Please enter a valid phone number.");
    }
    return phoneNumberObject?.isValid();
  };

  const handlevisiblelUserAssignedDropdownChange = (visible, cardId) => {
    setvisiblelUserAssignedDropdown(visible ? cardId : null);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  const getTreatmentUrlList = async () => {
    const token = localStorage.getItem("authToken");
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/get-treatmentOptions?website_name=${selectedItemDetails?.website_user_name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        settreatmentOptions(res?.data?.treatmentOptionList?.treatments);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelApointment = async () => {
    const token = localStorage.getItem("authToken");
    let data = {
      id: selectedItemDetails?.id,
      lead_status: "Appointment",
      appointment_status: "Cancelled",
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/update-leads`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbuttonLoader(false);
        setisLeadsDetailsModalVisible(false);
        setisViewLeadModalEditable(false);
        openNotificationWithIcon(
          "success",
          "Lead",
          "Lead Updated Successfully !"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitUpdateleads = async (values) => {
    values.id = selectedItemDetails?.id;
    setbuttonLoader(true);

    const valid = await handleValidation(
      selectedCountryPhoneNumber?.dialCode,
      ViewUpdateLeadform?.getFieldsValue()?.phone_number
    );

    if (!valid) {
      message.error("Please select country code");
      setbuttonLoader(false);
      return;
    }

    let phoneNumber =
      selectedCountryPhoneNumber?.dialCode +
      ViewUpdateLeadform?.getFieldsValue()?.phone_number;

    values.phone_number = phoneNumber;
    const token = localStorage.getItem("authToken");
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/update-leads`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        ViewUpdateLeadform.resetFields();
        setbuttonLoader(false);
        setisLeadsDetailsModalVisible(false);
        setisViewLeadModalEditable(false);
        openNotificationWithIcon(
          "success",
          "Lead",
          "Lead Updated Successfully !"
        );
        setmoaltrigger(true);
      })
      .catch((err) => {
        console.log(err);
        ViewUpdateLeadform.resetFields();

        openNotificationWithIcon(
          "error",
          "Lead",
          err?.response?.data?.message || err?.message
        );
      });
    setbuttonLoader(false);
  };

  const fetchCountries = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const data = await response.json();
      const countryList = data.map((country) => ({
        name: country.name.common,
        code: country.cca2,
        dialCode: country.idd?.root + (country.idd?.suffixes?.[0] || ""),
        flag: country.flags.svg,
      }));
      if (selectedItemDetails?.phone_number) {
        const phoneNumber = parsePhoneNumber(selectedItemDetails?.phone_number);

        if (phoneNumber && phoneNumber?.isValid()) {
          const country = countries.find(
            (c) => c.dialCode === `+${phoneNumber.countryCallingCode}`
          );

          if (country) {
            setselectedCountryPhoneNumber({
              code: country.code,
              dialCode: country.dialCode,
            });
            ViewUpdateLeadform?.setFieldValue(
              "phone_number",
              phoneNumber?.nationalNumber
            );
          } else {
            setselectedCountryPhoneNumber();
          }
        }
      }
      setCountries(countryList);
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const handleGetLeadActivity = async () => {
    const token = localStorage.getItem("authToken");
    setbuttonLoader(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/getLeadActivity/${selectedItemDetails?.id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbuttonLoader(false);

        if (res.data?.latestClinicActivity) {
          let temp = res.data?.latestClinicActivity?.map((item) => {
            return {
              icon: (
                <div
                  style={{
                    borderRadius: "50%",
                    border: "1px solid #72779E",
                    padding: 5,
                  }}
                >
                  {item?.activity_name === "AI Calls" ? (
                    <AICallsSVG color={"#72779E"} style={{ width: 20 }} />
                  ) : item?.activity_name === "Conversational AI" ? (
                    <ConversationalAISVG
                      color={"#72779E"}
                      style={{ width: 20 }}
                    />
                  ) : item?.activity_name === "Appointments" ? (
                    <AppointmentsSVG color={"#72779E"} style={{ width: 20 }} />
                  ) : item?.activity_name === "Conversations" ? (
                    <ConversationalAISVG
                      color={"#72779E"}
                      style={{ width: 20 }}
                    />
                  ) : item?.activity_name === "Leads" ? (
                    <UserOutLineSvg color={"#72779E"} width={20} height={20} />
                  ) : (
                    ""
                  )}
                </div>
              ),

              title: (
                <>
                  {item?.activity_name === "Leads" &&
                  item?.activity_type === "Created" ? (
                    <>
                      {item?.meta_data?.first_name +
                        " " +
                        item?.meta_data?.last_name}
                      was added to the system
                      {item?.meta_data?.utm_source ? (
                        <>from {item?.meta_data?.utm_source}</>
                      ) : (
                        ""
                      )}
                    </>
                  ) : item?.activity_name === "Leads" &&
                    item?.activity_type === "Updated" ? (
                    <>
                      {item?.diff_summary[0]?.fields?.join(", ")} was updated to{" "}
                      {item?.diff_summary[0]?.new?.join(", ")}
                    </>
                  ) : item?.activity_name === "Leads" &&
                    item?.activity_type === "Assigned" ? (
                    <>
                      {item?.meta_data?.first_name +
                        " " +
                        item?.meta_data?.last_name}{" "}
                      was assigned to {item?.meta_data?.User?.dentist_full_name}
                    </>
                  ) : item?.activity_name === "Leads" &&
                    item?.activity_type === "Note Add" ? (
                    <span style={{ whiteSpace: "nowrap" }}>
                      A note was added for {item?.meta_data?.first_name}{" "}
                      {item?.meta_data?.last_name}:{" "}
                      <span
                        style={{ display: "inline", whiteSpace: "nowrap" }}
                        dangerouslySetInnerHTML={{
                          __html: item?.meta_data?.Notes?.[0]?.content,
                        }}
                      />
                    </span>
                  ) : item?.activity_name === "Leads" &&
                    item?.activity_type === "Note Updated" ? (
                    <span style={{ whiteSpace: "nowrap" }}>
                      A note was updated for {item?.meta_data?.first_name}{" "}
                      {item?.meta_data?.last_name}:{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.meta_data?.Notes?.[0]?.content,
                        }}
                      />
                    </span>
                  ) : item?.activity_name === "Appointments" &&
                    item?.activity_type === "Schedule" ? (
                    <>
                      {item?.meta_data?.first_name +
                        " " +
                        item?.meta_data?.last_name}{" "}
                      Booked an appointment for{" "}
                      {item?.meta_data?.appointment_date_time
                        ? dayjs(item?.meta_data?.appointment_date_time)?.format(
                            "MMM DD,YYYY HH:mm A"
                          )
                        : ""}
                    </>
                  ) : item?.activity_name === "Appointments" &&
                    item?.activity_type === "Confirmed" ? (
                    <>
                      {item?.meta_data?.first_name +
                        " " +
                        item?.meta_data?.last_name}{" "}
                      appointment on date{" "}
                      {item?.meta_data?.appointment_date_time
                        ? dayjs(item?.meta_data?.appointment_date_time)?.format(
                            "MMM DD,YYYY HH:mm A"
                          )
                        : ""}{" "}
                      confirmed
                    </>
                  ) : item?.activity_name === "Appointments" &&
                    item?.activity_type === "Requested" ? (
                    <>
                      {item?.meta_data?.first_name +
                        " " +
                        item?.meta_data?.last_name}{" "}
                      requested to reschedule their appointment originally set
                      for{" "}
                      {item?.meta_data?.appointment_date_time
                        ? dayjs(item?.meta_data?.appointment_date_time)?.format(
                            "MMM DD,YYYY HH:mm A"
                          )
                        : ""}{" "}
                    </>
                  ) : item?.activity_name === "Appointments" &&
                    item?.activity_type === "Reminder" ? (
                    <span style={{ whiteSpace: "nowrap" }}></span>
                  ) : (
                    ""
                  )}
                </>
              ),
              datetime: (
                <Space>
                  <FaClock
                    className="custom-text"
                    style={{ display: "flex" }}
                  />
                  <Typography className="custom-text">
                    {dayjs(item?.updated_at)?.format("MMM dd, YYYY - HH:mm A")}
                  </Typography>
                </Space>
              ),
              actions: <Space></Space>,
            };
          });

          setleadActivityDetails(temp);
        } else {
          setleadActivityDetails([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchCountries();

    getTreatmentUrlList();
  }, [selectedItemDetails]);

  useEffect(() => {
    if (activeKey === "3") {
      handleGetLeadActivity();
      console.log(activeKey);
    }
  }, [activeKey]);

  return (
    <Modal
      style={{ top: 10 }}
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => {
                setActiveKey("1");
                setisLeadsDetailsModalVisible(false);
                setisViewLeadModalEditable(false);
              }}
              icon={<IoChevronBackSharp />}
            ></Button>
            <Typography style={{ marginLeft: 10 }}>Lead Details</Typography>
          </div>
          <Space style={{ display: "flex", alignItems: "center" }}>
            {/* {console.log(process.env.REACT_APP_NODE_ENV )} */}
            {process.env.REACT_APP_NODE_ENV !== "production" ? (
              <>
                <Space>
                  <IoMdMic style={{ fontSize: 16, display: "flex" }} />
                  <Typography>AI Agent</Typography>
                </Space>
                <Switch defaultChecked style={{ marginLeft: 10 }} />
              </>
            ) : (
              ""
            )}

            {websiteAccesRoles?.some((data) => {
              const permissionGroups = data?.role?.permission_group?.split(",");
              return (
                data?.accesslevel?.lead &&
                data?.website?.website_user_name ===
                  selectedItemDetails?.website_user_name &&
                permissionGroups?.includes("LAT:RLTTM")
              );
            }) && (
              <Dropdown
                overlay={
                  <ClinicUserOptionsList
                    clinicUsers={clinicUsers}
                    lead={selectedItemDetails}
                    handlevisiblelUserAssignedDropdownChange={
                      handlevisiblelUserAssignedDropdownChange
                    }
                    openNotificationWithIcon={openNotificationWithIcon}
                    setisLeadsDetailsModalVisible={
                      setisLeadsDetailsModalVisible
                    }
                  />
                }
                trigger={["click"]}
                placement="bottomCenter"
                visible={
                  visiblelUserAssignedDropdown === selectedItemDetails?.id
                }
                onVisibleChange={(visible) =>
                  handlevisiblelUserAssignedDropdownChange(
                    visible,
                    selectedItemDetails?.id
                  )
                }
              >
                <div
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: 8,

                    width: "auto",
                    padding: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {selectedItemDetails?.User ? (
                    <>
                      {selectedItemDetails?.User?.profile_picture ? (
                        <Avatar
                          size={25}
                          src={selectedItemDetails?.User?.profile_picture}
                        ></Avatar>
                      ) : (
                        <Avatar
                          style={{
                            backgroundColor:
                              selectedItemDetails?.User?.avatar_color,
                          }}
                          size={25}
                        >
                          {selectedItemDetails?.User?.dentist_full_name
                            ? getInitials(
                                selectedItemDetails?.User?.dentist_full_name
                              )
                            : ""}
                        </Avatar>
                      )}
                    </>
                  ) : (
                    <Typography className="custom-text1">Assign</Typography>
                  )}

                  <MdOutlineKeyboardArrowDown className="custom-text1" />
                </div>
              </Dropdown>
            )}
            {websiteAccesRoles?.some((data) => {
              const permissionGroups = data?.role?.permission_group?.split(",");
              return (
                data?.accesslevel?.lead &&
                data?.website?.website_user_name ===
                  selectedItemDetails?.website_user_name &&
                permissionGroups?.includes("LAT:ULD")
              );
            }) && (
              <div style={{ marginLeft: 10 }}>
                <Tooltip title={"Edit"}>
                  <Button
                    style={{ background: "none" }}
                    onClick={() => {
                      setisViewLeadModalEditable(true);
                    }}
                    icon={<FiEdit />}
                  ></Button>
                </Tooltip>
              </div>
            )}

            {selectedItemDetails?.appointment_status === "Confirmed" ? (
              <>
                <div
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: 8,
                    height: 30,
                    width: 30,
                    display: "flex",
                    marginLeft: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={"Cancel Appointment"}>
                    <Button
                      style={{ background: "none" }}
                      onClick={() => {
                        handleCancelApointment();
                      }}
                      icon={<MdOutlineCancel style={{ fontSize: 16 }} />}
                    />
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )}
          </Space>
        </div>
      }
      open={isLeadsDetailsModalVisible}
      closable={false}
      width={900}
      className="custom-modal-lead-details "
      footer={[
        isViewLeadModalEditable ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 10px 0px 10px",
            }}
          >
            <div>
              <Typography style={{ marginLeft: 10, display: "flex" }}>
                <MdOutlineError
                  style={{
                    fontSize: 20,
                    color: "#72779E",
                    display: "flex",
                  }}
                />
                You have unsaved changes
              </Typography>
            </div>
            <div>
              <Button
                onClick={() => {
                  setisLeadsDetailsModalVisible(false);
                  setisViewLeadModalEditable(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                loading={buttonLoader}
                onClick={() => {
                  ViewUpdateLeadform.submit();
                }}
                style={{ marginLeft: 10 }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          ""
        ),
      ]}
    >
      {/* Modal Header */}

      <Row align="middle" gutter={16} style={{ marginBottom: 10, margin: 15 }}>
        {/* Avatar Column */}
        <Col>
          <Avatar
            size={45}
            style={{ backgroundColor: selectedItemDetails?.avatar_color }}
          >
            {getInitials(
              selectedItemDetails?.first_name +
                " " +
                selectedItemDetails?.last_name
            )}
          </Avatar>
        </Col>

        {/* Details Column */}
        <Col span={22}>
          <Row>
            <Col span={24}>
              {/* Lead ID */}
              <Text type="secondary" style={{ fontSize: "14px" }}>
                #{selectedItemDetails?.id || "-"}
              </Text>
            </Col>
            <Col span={24}>
              {/* Lead Name and Verified Tag */}
              <Title level={4} style={{ marginTop: 0 }}>
                {selectedItemDetails?.first_name || "-"}{" "}
                {selectedItemDetails?.last_name}{" "}
                <Tag
                  style={{
                    color: "#12B80F",
                    display: "inline-flex",
                    background: "none",
                    border: "none",
                  }}
                >
                  <RiVerifiedBadgeFill
                    style={{
                      color: "#12B80F",
                      fontSize: 14,
                      margin: "3px 3px",
                    }}
                  />
                  Verified
                </Tag>
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Action Buttons */}
      <div className="lead-modal-actions" style={{ margin: 15 }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Space size="middle">
              <Button
                onClick={() => {
                  setisLeadsDetailsModalVisible(false);
                  setisVisibleQuickConversation(true);
                  setquickConversationView("call");
                  setselectedConversationDetails(selectedItemDetails);
                }}
                icon={<PhoneOutlined />}
              >
                Call
              </Button>
              <Button
                onClick={() => {
                  setisLeadsDetailsModalVisible(false);
                  setisVisibleQuickConversation(true);
                  setquickConversationView("sms");
                  setselectedConversationDetails(selectedItemDetails);
                }}
                icon={<MessageOutlined />}
              >
                SMS
              </Button>
              <Button
                onClick={() => {
                  setisLeadsDetailsModalVisible(false);
                  setisVisibleQuickConversation(true);
                  setquickConversationView("email");
                  setselectedConversationDetails(selectedItemDetails);
                }}
                icon={<MailOutlined />}
              >
                Email
              </Button>
              <Button
                icon={<FileAddOutlined />}
                onClick={() => {
                  setActiveKey("2");
                }}
              >
                Add Note
              </Button>
            </Space>
          </Col>
          <Col>
            {selectedItemDetails?.appointment_date_time ? (
              <Button
                type="primary"
                className="book-appointment-btn"
                onClick={() => {
                  setisCloseLeadsPaymentModalVisible(true);
                }}
              >
                <Paymentsvg />
                Record Payment
              </Button>
            ) : (
              <Button
                type="primary"
                className="book-appointment-btn"
                onClick={() => {
                  setisAppointmentModalVisible(true);
                }}
              >
                Book Appointment
              </Button>
            )}
          </Col>
        </Row>
      </div>

      {/* Tabs */}
      <Tabs
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        className="lead-modal-tabs"
        style={{ marginRight: 15 }}
      >
        <TabPane tab="Overview" key="1">
          <div className="overview-section">
            <Row
              gutter={[16, 16]}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                margin: 10,
                padding: 15,
                border: "1px solid #E8EBEF",
                borderRadius: 10,
              }}
            >
              <Col>
                <Text className="custom-text1">Response Time</Text>
                <br />
                00:02:32
                <span className="text-success">+5% Faster</span>
              </Col>
              <Divider type="vertical" style={{ margin: 0, fontSize: 50 }} />
              <Col>
                <Text className="custom-text1">Treatment Value</Text>
                <br />
                {selectedItemDetails?.treatment_value
                  ? "$" +
                    Number(selectedItemDetails?.treatment_value).toLocaleString(
                      "en-US"
                    )
                  : 0}
              </Col>
              <Divider type="vertical" style={{ margin: 0, fontSize: 50 }} />
              <Col>
                <Text className="custom-text1">Status</Text>
                <br />
                <Tag
                  style={{
                    backgroundColor: leadStatusColorAndTextList.find(
                      (item) => item.status === selectedItemDetails?.lead_status
                    )?.backgroud,
                    color: leadStatusColorAndTextList.find(
                      (item) => item.status === selectedItemDetails?.lead_status
                    )?.color,
                    border: "none",
                  }}
                >
                  {selectedItemDetails?.lead_status
                    ? leadStatusColorAndTextList.find(
                        (item) =>
                          item.status === selectedItemDetails?.lead_status
                      )?.text
                    : leadStatusColorAndTextList.find(
                        (item) => item.status === "AllLeads"
                      )?.text}
                </Tag>
              </Col>
              <Divider type="vertical" style={{ margin: 0, fontSize: 50 }} />
              <Col>
                <Text className="custom-text1">Appointment</Text>
                <br />

                <Tag
                  style={{
                    background: "transparent",
                    color: leadStatusColorAndTextList.find(
                      (item) =>
                        item.status === selectedItemDetails?.appointment_status
                    )?.color,
                    border: "none",
                  }}
                >
                  {selectedItemDetails?.appointment_status
                    ? leadStatusColorAndTextList.find(
                        (item) =>
                          item.status ===
                          selectedItemDetails?.appointment_status
                      )?.text
                    : leadStatusColorAndTextList.find(
                        (item) => item.status === "AllLeads"
                      )?.text}
                </Tag>
              </Col>
              {selectedItemDetails?.appointment_date_time ? (
                <>
                  <Divider
                    type="vertical"
                    style={{ margin: 0, fontSize: 50 }}
                  />
                  <Col>
                    <Text className="custom-text1">
                      Appointment Time & Date
                    </Text>
                    <br />
                    {selectedItemDetails?.appointment_date_time
                      ? dayjs(
                          selectedItemDetails?.appointment_date_time
                        ).format("MMM DD YYYY, hh:mm A")
                      : ""}
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <div
              gutter={[16, 16]}
              style={{
                padding: 10,
                height: "40vh",
                overflow: "auto",
              }}
            >
              <Space
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  marginTop: 10,
                }}
              >
                <TiUser
                  style={{
                    fontSize: 20,
                    color: "#72779E",
                    display: "flex",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Contact
                </Typography>
              </Space>

              <Form
                form={ViewUpdateLeadform}
                onFinish={handleSubmitUpdateleads}
                layout="vertical"
              >
                <Divider style={{ margin: "10px 0px 10px 0px" }}></Divider>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">Name</Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="first_name">
                            <Input
                              defaultValue={selectedItemDetails?.first_name}
                              placeholder="Enter first name"
                            />
                          </Form.Item>
                          <Form.Item name="last_name">
                            <Input
                              defaultValue={selectedItemDetails?.last_name}
                              placeholder="Enter last name"
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            {selectedItemDetails?.first_name || "-"}{" "}
                            {selectedItemDetails?.last_name}
                          </div>
                        </Typography>
                      )}
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Lead Status
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="lead_status">
                            <Select
                              defaultValue={selectedItemDetails?.lead_status}
                              style={{ width: "220px" }}
                              placeholder="Select Lead Status"
                              suffixIcon={
                                <MdOutlineKeyboardArrowDown
                                  style={{ fontSize: 20 }}
                                />
                              }
                              options={leadsStatusList}
                            ></Select>
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            <Tag
                              style={{
                                backgroundColor:
                                  leadStatusColorAndTextList.find(
                                    (item) =>
                                      item.status ===
                                      selectedItemDetails?.lead_status
                                  )?.backgroud,
                                color: leadStatusColorAndTextList.find(
                                  (item) =>
                                    item.status ===
                                    selectedItemDetails?.lead_status
                                )?.color,
                                border: "none",
                              }}
                            >
                              {selectedItemDetails?.lead_status
                                ? leadStatusColorAndTextList.find(
                                    (item) =>
                                      item.status ===
                                      selectedItemDetails?.lead_status
                                  )?.text
                                : leadStatusColorAndTextList.find(
                                    (item) => item.status === "AllLeads"
                                  )?.text}
                            </Tag>
                          </div>
                        </Typography>
                      )}
                    </div>

                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">Email</Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="email">
                            <Input
                              defaultValue={selectedItemDetails?.email}
                              style={{ width: "220px" }}
                              type="email"
                              placeholder="Enter Valid Email"
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            <Typography className="custom-text-link">
                              {selectedItemDetails?.email || "-"}
                              {selectedItemDetails?.email_verify ===
                              "unverified" ? (
                                <></>
                              ) : (
                                <RiVerifiedBadgeFill
                                  style={{
                                    color: "#12B80F",
                                    fontSize: 14,
                                    marginLeft: 5,
                                  }}
                                />
                              )}
                            </Typography>
                          </div>
                        </Typography>
                      )}
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">Label</Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="lable">
                            <Input
                              style={{ width: "220px" }}
                              type="Lable"
                              placeholder="Enter Label"
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            <Typography className="custom-text-link">
                              -
                            </Typography>
                          </div>
                        </Typography>
                      )}
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Phone Number
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item required>
                            <Input.Group compact>
                              <Select
                                showSearch
                                style={{ width: "40%" }}
                                value={
                                  selectedCountryPhoneNumber?.code &&
                                  selectedCountryPhoneNumber?.dialCode
                                    ? `${selectedCountryPhoneNumber?.code}|${selectedCountryPhoneNumber?.dialCode}`
                                    : undefined
                                }
                                placeholder="search code"
                                onChange={handleCountryChange}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                  const searchText = input?.toLowerCase();
                                  const dialCode = option?.value.split("|")[1];
                                  return dialCode?.includes(searchText);
                                }}
                              >
                                {countries.map((country) => (
                                  <Option
                                    placeholder="search code"
                                    key={country?.code}
                                    value={`${country?.code}|${country?.dialCode}`}
                                  >
                                    <span>
                                      <img
                                        src={country?.flag}
                                        alt={country?.name}
                                        style={{
                                          width: 16,
                                          height: 12,
                                          marginRight: 8,
                                          borderRadius: 2,
                                        }}
                                      />
                                      ({country?.dialCode})
                                    </span>
                                  </Option>
                                ))}
                              </Select>
                              <Form.Item
                                noStyle
                                name="phone_number"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter phone number !",
                                  },
                                ]}
                              >
                                <Input
                                  className="custom-text1"
                                  style={{ width: "60%" }}
                                  placeholder="Enter phone number"
                                />
                              </Form.Item>
                            </Input.Group>
                          </Form.Item>
                          {/* <Form.Item name="phone_number">
                            <Input
                              defaultValue={selectedItemDetails?.phone_number}
                              style={{ width: "220px" }}
                              placeholder="Enter Phone Number"
                            />
                          </Form.Item> */}
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText custom-text-link">
                          {selectedItemDetails?.phone_number}
                          {selectedItemDetails?.phone_verify ===
                          "unverified" ? (
                            <></>
                          ) : (
                            <RiVerifiedBadgeFill
                              style={{
                                color: "#12B80F",
                                fontSize: 14,
                                marginLeft: 5,
                              }}
                            />
                          )}
                        </Typography>
                      )}
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Lead Source
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="LeadSource">
                            <Input
                              defaultValue={selectedItemDetails?.utm_source}
                              style={{ width: "220px" }}
                              placeholder="Please Enter Lead Source"
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            {selectedItemDetails?.utm_source || "-"}
                          </div>
                        </Typography>
                      )}
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        How to Contact
                      </Typography>
                      <Typography>Phone Number</Typography>
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Lead Type
                      </Typography>
                      <Typography>
                        {selectedItemDetails?.lead_type ? "#" : ""}
                        {selectedItemDetails?.lead_type || "-"}
                      </Typography>
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>

                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        className="custom-text1"
                        style={{ width: 100 }}
                      >
                        Treatment
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="treatment">
                            <Select
                              defaultValue={selectedItemDetails?.treatment}
                              style={{ width: "220px" }}
                              placeholder="Please select the treatment"
                              onChange={(value, val2) => {
                                ViewUpdateLeadform?.setFieldsValue({
                                  treatment_value: val2?.treatment_value || "",
                                });
                              }}
                              options={treatmentOptions?.map((item) => ({
                                label: item?.treatment,
                                value: item?.treatment,
                                ...item,
                              }))}
                            />
                          </Form.Item>
                          <Form.Item
                            name="treatment_value"
                            style={{ display: "none" }}
                          >
                            <input type="hidden" />
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            {selectedItemDetails?.treatment || "-"}
                          </div>
                        </Typography>
                      )}
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>

                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Description
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="description">
                            <Input
                              defaultValue={selectedItemDetails?.description}
                              style={{ width: "220px" }}
                              placeholder="Description"
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            {selectedItemDetails?.Description || "-"}
                          </div>
                        </Typography>
                      )}
                    </div>
                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                </Row>
                <Space
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    marginTop: 10,
                  }}
                >
                  <Moneybagsvg
                    style={{
                      fontSize: 16,
                      color: "#72779E",
                      display: "flex",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Financing
                  </Typography>
                </Space>

                <Divider style={{ margin: "10px 0px 10px 0px" }}></Divider>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Credit Score
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="credit_score">
                            <Select
                              defaultValue={selectedItemDetails?.credit_score}
                              style={{ width: "220px" }}
                              placeholder="Select Credit Score"
                              suffixIcon={
                                <MdOutlineKeyboardArrowDown
                                  style={{ fontSize: 20 }}
                                />
                              }
                              options={creditScoreList}
                            ></Select>
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            {selectedItemDetails?.credit_score || "-"}
                          </div>
                        </Typography>
                      )}
                    </div>

                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>

                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Co-signer above 650
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="co_signer">
                            <Select
                              defaultValue={
                                selectedItemDetails?.co_signer === "Y"
                                  ? "Yes"
                                  : "No"
                              }
                              style={{ width: "220px" }}
                              placeholder="Select Yes/No"
                              suffixIcon={
                                <MdOutlineKeyboardArrowDown
                                  style={{ fontSize: 20 }}
                                />
                              }
                            >
                              <Option value="Y">Yes</Option>
                              <Option value="N">No</Option>
                            </Select>
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            <Typography>
                              {selectedItemDetails?.co_signer === "Y"
                                ? "Yes"
                                : "No"}
                            </Typography>
                          </div>
                        </Typography>
                      )}
                    </div>

                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Home Owner
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="home_owner">
                            <Select
                              defaultValue={
                                selectedItemDetails?.home_owner === "Y"
                                  ? "Yes"
                                  : "No"
                              }
                              style={{ width: "220px" }}
                              placeholder="Select Home Owner Status"
                              suffixIcon={
                                <MdOutlineKeyboardArrowDown
                                  style={{ fontSize: 20 }}
                                />
                              }
                            >
                              <Option value="Y">Yes</Option>
                              <Option value="N">No</Option>
                            </Select>
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            <Typography>
                              {selectedItemDetails?.home_owner === "Y"
                                ? "Yes"
                                : "No"}
                            </Typography>
                          </div>
                        </Typography>
                      )}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Annual Salary
                      </Typography>
                      {isViewLeadModalEditable ? (
                        <>
                          <Form.Item name="annual_salary">
                            <Input
                              defaultValue={selectedItemDetails?.annual_salary}
                              style={{ width: "220px" }}
                              placeholder="Enter Annual Salary"
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <Typography className="isViewLeadEditableText">
                          <div className="editableTextContainer">
                            <Typography>
                              {selectedItemDetails?.annual_salary || "-"}
                            </Typography>
                          </div>
                        </Typography>
                      )}
                    </div>
                  </Col>
                </Row>

                <Space
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    marginTop: 10,
                  }}
                >
                  <MdOutlineError
                    style={{
                      fontSize: 20,
                      color: "#72779E",
                      display: "flex",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Details
                  </Typography>
                </Space>
                <Divider style={{ margin: "10px 0px 10px 0px" }}></Divider>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Created Time
                      </Typography>
                      <Typography>
                        {dayjs(selectedItemDetails?.created_on)?.format(
                          "YYYY-MM-DD hh:mm A"
                        ) || "-"}{" "}
                      </Typography>
                    </div>

                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>

                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="custom-text1">
                        Unique ID
                      </Typography>
                      <Typography>{selectedItemDetails?.id || "-"} </Typography>
                    </div>

                    <Divider style={{ margin: "10px 0px 0px 0px" }} />
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </TabPane>

        <TabPane tab="Notes" key="2">
          <div style={{ height: "50vh", overflow: "auto", padding: 15 }}>
            {/* Render the comments */}
            {/* <List
                  itemLayout="vertical"
                  dataSource={commentsData}
                  renderItem={(item) => (
                    <Card
                      style={{ border: "none" }}
                      className="custom-card-notes"
                    >
                      <List.Item
                        actions={item.actions ? [item.actions] : []}
                        style={{ borderBottom: "none" }}
                      >
                        <List.Item.Meta
                          avatar={<Avatar src={item.avatar} size={50} />}
                          title={
                            <span style={{ fontWeight: "bold" }}>
                              {item.author}
                            </span>
                          }
                          description={
                            <span style={{ color: "#888" }}>
                              {item.datetime}
                            </span>
                          }
                        />
                        <div
                          style={{
                            marginTop: "10px",
                            fontSize: "14px",
                            color: "#333",
                          }}
                        >
                          {item.content}
                        </div>
                      </List.Item>
                      <Divider style={{ margin: 0 }} />
                    </Card>
                  )}
                /> */}
            {/* Write a Note Section */}
            <Notes
              selectedLeadDetails={selectedItemDetails}
              openNotificationWithIcon={openNotificationWithIcon}
              clinicUsers={clinicUsers}
            />
            <Card
              style={{
                border: "none",
              }}
              className="custom-card-notes"
            ></Card>
          </div>
        </TabPane>

        <TabPane tab="Activity" key="3">
          <Card
            title="Engagement History"
            style={{
              height: "50vh",
              overflow: "auto",
              border: 0,
            }}
          >
            {buttonLoader ? (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Spin />
              </Row>
            ) : (
              <>
                {leadActivityDetails?.length <= 0 ? (
                  <Empty />
                ) : (
                  <Timeline>
                    {leadActivityDetails?.map((item, index) => (
                      <Timeline.Item key={index} dot={item.icon}>
                        <div style={{ marginLeft: 10 }}>
                          <Text>{item.title}</Text>
                          <div style={{ color: "#72779E", marginBottom: 8 }}>
                            {item.datetime}
                          </div>
                          {item.actions && (
                            <div style={{ marginBottom: 0 }}>
                              {item.actions}
                            </div>
                          )}
                        </div>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                )}
              </>
            )}
          </Card>
        </TabPane>

        <TabPane tab="Appointment" key="4">
          <div style={{ padding: 20 }}>
            <Form
              form={ViewUpdateLeadform}
              onFinish={handleSubmitUpdateleads}
              layout="vertical"
            >
              <Row>
                <Col span={12}>
                  <Text strong className="custom-text1">
                    Date & Time
                  </Text>
                </Col>

                <Col span={12}>
                  {isViewLeadModalEditable && haveAppointmentEditAccess ? (
                    <>
                      <Form.Item name="appointment_date_time">
                        <DatePicker
                          style={{ width: "100%" }}
                          className="custom-text1"
                          showTime
                          defaultValue={
                            selectedItemDetails?.appointment_date_time
                              ? dayjs(
                                  selectedItemDetails?.appointment_date_time
                                )
                              : null // default to null if the value is empty
                          }
                          format="YYYY-MMM-DD hh:mm A"
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Text>
                      {selectedItemDetails?.appointment_date_time
                        ? dayjs(
                            selectedItemDetails?.appointment_date_time
                          ).format("MMM DD,YYYY")
                        : "-"}{" "}
                      {selectedItemDetails?.appointment_date_time
                        ? dayjs(
                            selectedItemDetails?.appointment_date_time
                          ).format("hh:mm A")
                        : "-"}
                    </Text>
                  )}
                </Col>
              </Row>
              <Divider style={{ margin: "16px 0" }} />
              <Row>
                <Col span={12}>
                  <Text strong className="custom-text1">
                    Assigned
                  </Text>
                </Col>
                <Col span={12}>
                  <Text strong className="custom-text1">
                    Duration
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>
                    {selectedItemDetails?.assign_to
                      ? selectedItemDetails?.assign_to
                      : "-"}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>
                    {isViewLeadModalEditable && haveAppointmentEditAccess ? (
                      <>
                        <Form.Item name="appointment_duration">
                          <Select
                            defaultValue={
                              selectedItemDetails?.appointment_duration
                                ? selectedItemDetails?.appointment_duration
                                : ""
                            }
                            placeholder="Select Duration"
                            className="custom-text1"
                          >
                            <Option>30 minutes</Option>
                            <Option value="45">45 minutes</Option>
                            <Option value="60">1 hour</Option>
                          </Select>
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        {selectedItemDetails?.appointment_duration
                          ? selectedItemDetails?.appointment_duration
                          : "-"}
                        min
                      </>
                    )}
                  </Text>
                </Col>
              </Row>

              <Divider style={{ margin: "16px 0" }} />

              {/* Appointment Note Section */}
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Text strong className="custom-text1">
                    Appointment Note:
                  </Text>
                  <p style={{ marginTop: 8 }}>
                    {isViewLeadModalEditable && haveAppointmentEditAccess ? (
                      <>
                        <Form.Item name="appointment_notes">
                          <Input.TextArea
                            className="custom-text1"
                            defaultValue={
                              selectedItemDetails?.appointment_notes
                            }
                            placeholder="Please enter notes"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        {selectedItemDetails?.appointment_notes
                          ? selectedItemDetails?.appointment_notes
                          : "-"}
                      </>
                    )}
                  </p>
                </Col>
              </Row>
            </Form>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};
