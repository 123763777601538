/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  Space,
  Layout,
  Dropdown,
  Tabs,
  Select,
  Spin,
  Row,
} from "antd";
import { PiExportBold } from "react-icons/pi";
import { Content } from "antd/es/layout/layout";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import OverviewReports from "./OverviewReport";
import LeadsReport from "./LeadsReport";
import AppointmentsReport from "./AppointmentsReport";
import { IoIosArrowDown } from "react-icons/io";
import {
  CsvSVG,
  PdfSVG,
  Reportsvg,
  XclSVG,
} from "../../Common/SettingSidebarIconsSvg";
import { FiCalendar } from "react-icons/fi";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { ToptreatmentLeadList } from "./Modal";

dayjs.extend(customParseFormat);

const headerStyles = {
  padding: 0,
  background: "#fff",

  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const leftColStyles = {
  display: "flex",
  alignItems: "center",
  paddingLeft: 10,
};

const rightColStyles = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: 10,
};

const Reports = ({
  openNotificationWithIcon,
  loginUserDetails,
  setisLeadsDetailsModalVisible,
  setselectedItemDetails,
}) => {
  const [defaultActiveKey, setdefaultActiveKey] = useState(1);
  const [pageLoader, setpageLoader] = useState(false);
  const [overViewData, setoverViewData] = useState([]);
  const [reportLeadData, setreportLeadData] = useState([]);
  const [reportApointmentData, setreportApointmentData] = useState([]);
  const [searchValue, setsearchValue] = useState("Thismonth");
  const OverviewReportcontentRef = useRef(null);
  const LeadsReportcontentRef = useRef(null);
  const AppointmentsReportcontentRef = useRef(null);
  const [isToptreatmentLeadListModal, setisToptreatmentLeadListModal] =
    useState(false);

  const [selectedTreatmentName, setselectedTreatmentName] = useState("");
  const convertToPdf = () => {
    let content = "";

    if (defaultActiveKey === 1) {
      content = OverviewReportcontentRef.current;
    } else if (defaultActiveKey === 2) {
      content = LeadsReportcontentRef.current;
    } else if (defaultActiveKey === 3) {
      content = AppointmentsReportcontentRef.current;
    }

    const options = {
      filename: "report.pdf",
      margin: 1,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1, useCORS: true },
      jsPDF: {
        unit: "in",
        format: "a2",
        orientation: "portrait",
      },
    };

    if (content) {
      html2pdf().set(options).from(content).save();
    } else {
      console.log(content, "Content is not available for PDF generation.");
    }
  };

  const ExportOpitems = [
    {
      key: "pdf",
      icon: <PdfSVG size={15} />,
      label: <Typography style={{ marginLeft: 10 }}>Export PDF</Typography>,
      onClick: () => {
        convertToPdf();
      },
    },
    {
      key: "excel",
      icon: <XclSVG size={15} />,
      label: <Typography style={{ marginLeft: 10 }}>Export XLS</Typography>,
    },
    {
      key: "csv",
      icon: <CsvSVG size={15} />,
      label: <Typography style={{ marginLeft: 10 }}>Export As CSV</Typography>,
    },
  ];

  const onChange = (newActiveKey) => {
    setdefaultActiveKey(parseInt(newActiveKey));
    if (newActiveKey === "1") {
      getreportOverview();
    } else if (newActiveKey === "2") {
      getreportLead();
    } else if (newActiveKey === "3") {
      getreportApointment();
    }
  };

  const getreportOverview = async (page, limit, search, searchType) => {
    setpageLoader(true);
    setoverViewData([]);
    const token = localStorage.getItem("authToken");
    let data = {
      page: page || 1,
      limit: limit || 10,
      clinic_id: loginUserDetails?.userClinicRoles?.[0]?.clinic_id,
      search: search || searchValue,
      searchType: searchType?.trim() || "datetime",
    };

    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/reportOverview`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setpageLoader(false);
        setoverViewData(res?.data);
      });
    } catch (error) {
      setpageLoader(false);
      console.error(error);
    }
  };

  const getreportLead = async (page, limit, search, searchType) => {
    setpageLoader(true);
    setoverViewData([]);
    const token = localStorage.getItem("authToken");
    let data = {
      page: page || 1,
      limit: limit || 10,
      clinic_id: loginUserDetails?.userClinicRoles?.[0]?.clinic_id,
      search: search || searchValue,
      searchType: searchType?.trim() || "datetime",
    };

    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/reportLead`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setpageLoader(false);
        setreportLeadData(res?.data);
      });
    } catch (error) {
      setpageLoader(false);
      console.error(error);
    }
  };

  const getreportApointment = async (page, limit, search, searchType) => {
    setpageLoader(true);
    setoverViewData([]);
    const token = localStorage.getItem("authToken");
    let data = {
      page: page || 1,
      limit: limit || 10,
      clinic_id: loginUserDetails?.userClinicRoles?.[0]?.clinic_id,
      search: search || searchValue,
      searchType: searchType?.trim() || "datetime",
    };

    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/reportApointment`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setpageLoader(false);
        setreportApointmentData(res?.data);
      });
    } catch (error) {
      setpageLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (defaultActiveKey === 1) {
      getreportOverview("", "", searchValue, "");
    } else if (defaultActiveKey === 2) {
      getreportLead("", "", searchValue, "");
    } else if (defaultActiveKey === 3) {
      getreportApointment("", "", searchValue, "");
    }
  }, []);

  return (
    <>
      <Layout>
        <Layout.Header style={headerStyles} >
          <div style={leftColStyles}>
            <Reportsvg color={"#000"} />
            <Space style={{ marginLeft: 10 }}>
              <Typography style={{ fontWeight: "bold" }}>Clinic Report</Typography>
            </Space>
          </div>
          <div style={rightColStyles}>
            <Space>
              <Select
                placeholder="Select"
                suffixIcon={
                  <FiCalendar style={{ fontSize: 20, display: "flex" }} />
                }
                prefixIcon={
                  <FiCalendar style={{ fontSize: 20, display: "flex" }} />
                }
                style={{
                  width: "200px",
                }}
                onChange={(e, text) => {
                  console.log(text);
                  setsearchValue(text?.value);
                  if (defaultActiveKey === 1) {
                    getreportOverview("", "", e, "");
                  } else if (defaultActiveKey === 2) {
                    getreportLead("", "", e, "");
                  } else if (defaultActiveKey === 3) {
                    getreportApointment("", "", e, "");
                  }
                }}
                defaultValue={searchValue}
                options={[
                  // {
                  //   value: "All",
                  //   label: "All",
                  // },
                  {
                    value: "today",
                    label: "Today",
                  },
                  {
                    value: "Last7days",
                    label: "Last 7 days",
                  },
                  {
                    value: "Last14days",
                    label: "Last 14 days",
                  },
                  {
                    value: "Last30days",
                    label: "Last 30 days",
                  },
                  {
                    value: "Last3months",
                    label: "Last 3 months",
                  },
                  {
                    value: "Last6months",
                    label: "Last 6 months",
                  },
                  {
                    value: "Thismonth",
                    label: "This month",
                  },
                  {
                    value: "Thisyear",
                    label: "This year",
                  },
                ]}
              />

              <Dropdown menu={{ items: ExportOpitems }} placement="bottomLeft">
                <Button type="primary">
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <PiExportBold />
                    Export
                    <IoIosArrowDown />
                  </span>
                </Button>
              </Dropdown>
            </Space>
          </div>
        </Layout.Header>
        <Content>
          <Tabs
            activeKey={defaultActiveKey?.toString()}
            onChange={onChange}
            items={[
              {
                label: "Overview",
                key: "1",
                children: (
                  <>
                    {!pageLoader ? (
                      <OverviewReports
                        getreportOverview={getreportOverview}
                        overViewData={overViewData}
                        searchValue={searchValue}
                        loginUserDetails={loginUserDetails}
                        openNotificationWithIcon={openNotificationWithIcon}
                        OverviewReportcontentRef={OverviewReportcontentRef}
                        setisToptreatmentLeadListModal={
                          setisToptreatmentLeadListModal
                        }
                        setselectedTreatmentName={setselectedTreatmentName}
                      />
                    ) : (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: 200,
                        }}
                      >
                        <Spin size="large" />
                      </Row>
                    )}
                  </>
                ),
              },
              {
                label: "Leads",
                key: "2",
                children: (
                  <>
                    {!pageLoader ? (
                      <LeadsReport
                        getreportLead={getreportLead}
                        reportLeadData={reportLeadData}
                        searchValue={searchValue}
                        loginUserDetails={loginUserDetails}
                        openNotificationWithIcon={openNotificationWithIcon}
                        LeadsReportcontentRef={LeadsReportcontentRef}
                      />
                    ) : (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: 200,
                        }}
                      >
                        <Spin size="large" />
                      </Row>
                    )}
                  </>
                ),
              },
              {
                label: "Appointments",
                key: "3",
                children: (
                  <>
                    {!pageLoader ? (
                      <AppointmentsReport
                        getreporApointment={getreportApointment}
                        reportApointmentData={reportApointmentData}
                        searchValue={searchValue}
                        loginUserDetails={loginUserDetails}
                        openNotificationWithIcon={openNotificationWithIcon}
                        AppointmentsReportcontentRef={
                          AppointmentsReportcontentRef
                        }
                      />
                    ) : (
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: 200,
                        }}
                      >
                        <Spin size="large" />
                      </Row>
                    )}
                  </>
                ),
              },
              // {
              //   label: "Call",
              //   key: "4",
              //   children: (
              //     <>
              //       <TestReport
              //         getreporApointment={getreportApointment}
              //         reportApointmentData={reportApointmentData}
              //         searchValue={searchValue}
              //         loginUserDetails={loginUserDetails}
              //         openNotificationWithIcon={openNotificationWithIcon}
              //       />
              //     </>
              //   ),
              // },
            ]}
          />
          <ToptreatmentLeadList
            isToptreatmentLeadListModal={isToptreatmentLeadListModal}
            setisToptreatmentLeadListModal={setisToptreatmentLeadListModal}
            selectedTreatmentName={selectedTreatmentName}
            setselectedTreatmentName={setselectedTreatmentName}
            setselectedItemDetails={setselectedItemDetails}
            setisLeadsDetailsModalVisible={setisLeadsDetailsModalVisible}
            searchValue={searchValue}
            loginUserDetails={loginUserDetails}
          />
        </Content>
      </Layout>
    </>
  );
};

export default Reports;
