/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Avatar,
  Progress,
  Dropdown,
  Menu,
  Space,
  Divider,
  DatePicker,
  Input,
  Button,
  Modal,
  List,
} from "antd";
import Chart from "react-apexcharts";
import {
  Moneybagsvg,
  MoneyCircle,
  ReportGoalSVG,
  RevenueMonthSVG,
  UserReportSVG,
} from "../../Common/SettingSidebarIconsSvg";
import { BsThreeDots } from "react-icons/bs";
import axios from "axios";
import dayjs from "dayjs";

const OverviewReport = ({
  getreportOverview,
  searchValue,
  overViewData,
  loginUserDetails,
  openNotificationWithIcon,
  OverviewReportcontentRef,
  setisToptreatmentLeadListModal,
  setselectedTreatmentName,
}) => {
  const [visibleMarketingBudgetDropdown, setvisibleMarketingBudgetDropdown] =
    useState(false);
  const [visibleSalesGoalDropdown, setvisibleSalesGoalDropdown] =
    useState(false);

  const [monthYear, setmonthYear] = useState();
  const [marketingBudget, setmarketingBudget] = useState();

  const [marketingBudgetForUpdate, setmarketingBudgetForUpdate] = useState();
  const [sale_goal, setsale_goal] = useState();

  const [buttonLoader, setbuttonLoader] = useState(false);
  const [
    visibleMarketingBudgetConfirmationModal,
    setvisibleMarketingBudgetConfirmationModal,
  ] = useState(false);

  const handlesetvisibleMarketingBudgetDropdown = (visible) => {
    setvisibleMarketingBudgetDropdown(visible);
  };

  const handlesetvisibleSalesGoalDropdown = (visible) => {
    setvisibleSalesGoalDropdown(visible);
  };

  const menu = (
    <Menu>
      <div
        style={{
          width: 300,
          maxHeight: "310px",
          overflowY: "auto",
          padding: 10,
        }}
      >
        <Space>
          <Moneybagsvg
            style={{
              fontSize: 16,
              color: "#72779E",
              display: "flex",
            }}
          />
          <Typography>Marketing Budget</Typography>
        </Space>
        <Divider style={{ margin: 0 }} />
        <div style={{ padding: 5 }}>
          <Typography style={{ fontWeight: 500 }}>
            Set Your Marketing Budget
          </Typography>
          <Typography className="custom-text1">
            Enter this month marketing budget to track ROI accurately.
          </Typography>

          <Typography className="custom-text1" style={{ marginTop: 10 }}>
            Month
          </Typography>
          <DatePicker
            picker="month"
            defaultValue={monthYear ? dayjs(monthYear, "MM-YYYY") : null}
            style={{ width: "100%" }}
            format={"MMM YYYY"}
            onChange={(e) => {
              setmonthYear(e?.format("MM-YYYY"));
            }}
          />
          <Typography className="custom-text1" style={{ marginTop: 10 }}>
            Budget
          </Typography>
          <Input
            placeholder="Please enter budget"
            value={marketingBudgetForUpdate}
            onChange={(e) => {
              setmarketingBudgetForUpdate(e?.target?.value);
            }}
            style={{ width: "100%" }}
          />
          <Divider />
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={() => {
                setvisibleMarketingBudgetDropdown(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!monthYear || !marketingBudget}
              type="primary"
              onClick={() => {
                setvisibleMarketingBudgetConfirmationModal(true);
              }}
            >
              Update Budget
            </Button>
          </Space>
        </div>
      </div>
    </Menu>
  );

  const salesGoalmenu = (
    <Menu>
      <div
        style={{
          width: 300,
          maxHeight: "310px",
          overflowY: "auto",
          padding: 10,
        }}
      >
        <Space>
          <Moneybagsvg
            style={{
              fontSize: 16,
              color: "#72779E",
              display: "flex",
            }}
          />
          <Typography>Sales Goal</Typography>
        </Space>
        <Divider style={{ margin: 0 }} />
        <div style={{ padding: 5 }}>
          <Typography style={{ fontWeight: 500 }}>
            Set Your Monthly Sales Goal
          </Typography>
          <Typography className="custom-text1">
            Define your sales target for the month to track your progress
            effectively.
          </Typography>
          <Typography className="custom-text1" style={{ marginTop: 10 }}>
            Month
          </Typography>
          <DatePicker
            picker="month"
            defaultValue={monthYear ? dayjs(monthYear, "MM-YYYY") : null}
            style={{ width: "100%" }}
            format={"MMM YYYY"}
            onChange={(e) => {
              setmonthYear(e?.format("MM-YYYY"));
            }}
          />
          <Typography className="custom-text1" style={{ marginTop: 10 }}>
            Sales Goal
          </Typography>

          <Input
            placeholder="Please enter sales goal"
            value={sale_goal}
            onChange={(e) => {
              setsale_goal(e?.target?.value);
            }}
            style={{ width: "100%" }}
          />
          <Divider />
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={() => {
                setvisibleSalesGoalDropdown(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!sale_goal}
              type="primary"
              loading={buttonLoader}
              onClick={() => {
                createOrUpdateMonthlyClinicBudget();
              }}
            >
              Update Budget
            </Button>
          </Space>
        </div>
      </div>
    </Menu>
  );

  const createOrUpdateMonthlyClinicBudget = async () => {
    setbuttonLoader(true);
    const token = localStorage.getItem("authToken");
    try {
      let data = {
        clinic_id: loginUserDetails?.userClinicRoles?.[0]?.clinic_id,
        month_year: monthYear,
        marketing_budget: visibleMarketingBudgetConfirmationModal
          ? marketingBudgetForUpdate
          : undefined,
        sale_goal: visibleSalesGoalDropdown ? sale_goal : undefined,
      };

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/createOrUpdateMonthlyClinicBudget`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setbuttonLoader(false);
        setmonthYear(res?.data?.data?.month_year);
        setsale_goal(res?.data?.data?.sale_goal);
        setmarketingBudget(res?.data?.data?.marketing_budget);
        setmarketingBudgetForUpdate(res?.data?.data?.marketing_budget);
        getreportOverview();
        setvisibleMarketingBudgetDropdown(false);
        setvisibleMarketingBudgetConfirmationModal(false);
        setvisibleSalesGoalDropdown(false);
        openNotificationWithIcon("success", "Report", res?.data?.message);
      });
    } catch (err) {
      setbuttonLoader(false);
      openNotificationWithIcon(
        "error",
        "Report",
        err?.response?.data?.message || err?.message
      );
    }
  };

  const getMonthlyClinicBudgets = async () => {
    const token = localStorage.getItem("authToken");
    try {
      await axios({
        method: "get",
        url: `${
          process.env.REACT_APP_API_BASE_URL
        }/api/v1/auth/getMonthlyClinicBudgets?clinic_id=${
          loginUserDetails?.userClinicRoles?.[0]?.clinic_id
        }&month_year=${dayjs()?.format("MM-YYYY")}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setsale_goal(res?.data?.budget?.sale_goal);
        setmonthYear(res?.data?.budget?.month_year);
        setmarketingBudget(res?.data?.budget?.marketing_budget);
        setmarketingBudgetForUpdate(res?.data?.budget?.marketing_budget);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMonthlyClinicBudgets();
  }, []);

  return (
    <div style={{ maxHeight: "80vh", overflow: "auto", padding: 20 }}>
      <div ref={OverviewReportcontentRef}>
        <Row gutter={[10, 10]}>
          <Col span={6}>
            <Card className="custom-report-card">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={40}
                    style={{ background: "#EFEEFF" }}
                    icon={<RevenueMonthSVG />}
                  />
                </Col>
                <Col>
                  <Typography>
                    $ {overViewData?.revenue?.totalRevenueAmount || 0}
                  </Typography>
                  <Typography className="custom-text1">
                    Revenue {searchValue}
                  </Typography>
                </Col>
              </Row>

              {Array.isArray(overViewData?.revenue?.revenueList) &&
                overViewData?.revenue?.revenueList.length > 0 && (
                  <Chart
                    options={{
                      chart: {
                        type: "area",
                        sparkline: { enabled: true }, // Enable sparkline for minimal design
                      },
                      xaxis: {
                        categories:
                          overViewData?.revenue?.revenueList?.map(
                            (item) => item?.period
                          ) || [], // Map periods for x-axis
                        labels: {
                          show: false, // Hide x-axis labels for sparkline
                        },
                      },
                      yaxis: {
                        show: false, // Hide y-axis for sparkline
                      },
                      colors: ["#3900DB"], // Set chart color
                      stroke: {
                        curve: "straight", // Use straight curve for area chart
                        width: 2, // Adjust stroke width for better visibility
                      },
                      tooltip: {
                        enabled: true, // Enable tooltip for interaction
                        x: {
                          formatter: (val) => `Period: ${val}`, // Format x-axis values in tooltip
                        },
                        y: {
                          formatter: (val) => `$${val}`, // Format y-axis values in tooltip
                        },
                      },
                    }}
                    series={[
                      {
                        name: "Revenue",
                        data:
                          overViewData?.revenue?.revenueList?.map(
                            (item) => item?.total_closed_amount
                          ) || [], // Map revenue data
                      },
                    ]}
                    type="area"
                    height={60} // Maintain a compact height for sparkline
                  />
                )}
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card">
              <Row
                gutter={[2, 2]}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Col>
                  <Avatar
                    size={40}
                    style={{ background: "#EFEEFF" }}
                    icon={<UserReportSVG />}
                  />
                </Col>
                <Col>
                  <Typography>
                    $ {overViewData?.activeLead?.totalActiveLeadTreatment || 0}
                  </Typography>
                  <Typography className="custom-text1">
                    {overViewData?.activeLead?.totalActiveLead} Active Leads
                    this {searchValue}
                  </Typography>
                </Col>
              </Row>

              {Array.isArray(overViewData?.activeLead?.activeLeadList) &&
              overViewData?.activeLead?.activeLeadList.length > 0 ? (
                <Chart
                  options={{
                    chart: {
                      type: "area",
                      sparkline: { enabled: true }, // Enable sparkline for minimalistic design
                    },
                    xaxis: {
                      categories:
                        overViewData?.activeLead?.activeLeadList?.map(
                          (item) => item?.period
                        ) || [], // Map periods for x-axis
                      labels: {
                        show: false, // Hide x-axis labels for clean design
                      },
                    },
                    yaxis: {
                      show: false, // Hide y-axis for a compact appearance
                    },
                    colors: ["#3900DB"], // Use a single color for the area chart
                    stroke: {
                      curve: "straight", // Straight curve for visual clarity
                      width: 2, // Slightly thicker stroke for better visibility
                    },
                    tooltip: {
                      enabled: true, // Enable tooltip for interactivity
                      x: {
                        formatter: (val) => `Period: ${val}`, // Format x-axis values
                      },
                      y: {
                        formatter: (val) => `${val} Active Leads`, // Format y-axis values
                      },
                    },
                  }}
                  series={[
                    {
                      name: "Active Leads",
                      data:
                        overViewData?.activeLead?.activeLeadList?.map(
                          (item) => item?.activeLeadCount
                        ) || [], // Map active lead counts
                    },
                  ]}
                  type="area"
                  height={60} // Compact height for a sparkline
                />
              ) : (
                <div>No active lead data available</div> // Fallback message
              )}
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card">
              <Row
                gutter={[6, 6]}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Row>
                  <Col>
                    <Avatar
                      size={40}
                      style={{ background: "#EFEEFF" }}
                      icon={<MoneyCircle />}
                    />
                  </Col>
                  <Col>
                    <Typography>
                      {isNaN(
                        (overViewData?.revenue?.totalRevenueAmount /
                          marketingBudget) *
                          100
                      )
                        ? "0%"
                        : `${(
                            (overViewData?.revenue?.totalRevenueAmount /
                              marketingBudget) *
                            100
                          ).toFixed(2)} %`}{" "}
                    </Typography>
                    <Typography className="custom-text1">
                      ROI this month
                    </Typography>
                  </Col>
                </Row>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomCenter"
                  visible={visibleMarketingBudgetDropdown}
                  onVisibleChange={handlesetvisibleMarketingBudgetDropdown}
                >
                  <BsThreeDots style={{ cursor: "pointer" }} />
                </Dropdown>
              </Row>

              {Array.isArray(overViewData?.revenue?.revenueList) &&
              overViewData?.revenue?.revenueList.length > 0 ? (
                <Chart
                  options={{
                    chart: {
                      type: "area",
                      sparkline: { enabled: true }, // Enable sparkline for minimalistic design
                    },
                    xaxis: {
                      categories:
                        overViewData?.revenue?.revenueList?.map(
                          (item) => item?.period
                        ) || [], // Map periods for the x-axis
                      labels: {
                        show: false, // Hide x-axis labels for compact design
                      },
                    },
                    yaxis: {
                      show: false, // Hide y-axis for a clean appearance
                    },
                    colors: ["#3900DB"], // Set the color for the chart
                    stroke: {
                      curve: "straight", // Straight lines for clarity
                      width: 2, // Thicker lines for better visibility
                    },
                    tooltip: {
                      enabled: true, // Enable tooltips for interaction
                      x: {
                        formatter: (val) => `Period: ${val}`, // Format the x-axis value in tooltips
                      },
                      y: {
                        formatter: (val) => `$${val}`, // Format y-axis value as currency
                      },
                    },
                  }}
                  series={[
                    {
                      name: "Revenue",
                      data:
                        overViewData?.revenue?.revenueList?.map(
                          (item) => item?.total_closed_amount
                        ) || [], // Map revenue amounts for the series data
                    },
                  ]}
                  type="area"
                  height={60} // Compact height for a sparkline
                />
              ) : (
                <div>No revenue data available</div> // Fallback message
              )}
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card">
              <Row
                gutter={[6, 6]}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Row>
                  <Col>
                    <Avatar
                      size={40}
                      style={{ background: "#EFEEFF" }}
                      icon={<ReportGoalSVG />}
                    />
                  </Col>
                  <Col>
                    <Typography>${marketingBudget}</Typography>
                    <Typography className="custom-text1">
                      Monthly Goal
                    </Typography>
                  </Col>
                </Row>
                <Dropdown
                  overlay={salesGoalmenu}
                  trigger={["click"]}
                  placement="bottomLeft"
                  visible={visibleSalesGoalDropdown}
                  onVisibleChange={handlesetvisibleSalesGoalDropdown}
                >
                  <BsThreeDots style={{ cursor: "pointer" }} />
                </Dropdown>
              </Row>

              <div style={{ marginTop: 14 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className="custom-text1">
                    Current: ${overViewData?.revenue?.totalRevenueAmount || 0}
                  </Typography>
                  <Typography>
                    {isNaN(
                      (overViewData?.revenue?.totalRevenueAmount /
                        marketingBudget) *
                        100
                    )
                      ? "0%"
                      : `${(
                          (overViewData?.revenue?.totalRevenueAmount /
                            marketingBudget) *
                          100
                        ).toFixed(2)} %`}
                  </Typography>
                </div>

                <Progress
                  strokeColor="#3900DB"
                  percent={(
                    (overViewData?.revenue?.totalRevenueAmount /
                      marketingBudget) *
                    100
                  ).toFixed(2)}
                  showInfo={false}
                />
              </div>
            </Card>
          </Col>
        </Row>

        {/* Main Charts */}
        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>Leads</Typography>
                  <Typography className="custom-text1">
                    Total Number of Leads {searchValue}
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    toolbar: {
                      show: false,
                    },
                  },

                  xaxis: {
                    categories:
                      searchValue === "Thismonth"
                        ? overViewData?.leadList?.map((item) =>
                            dayjs(item?.period, "YYYY-MM-DD").format("DD")
                          ) || [] // Format day for "This Month"
                        : overViewData?.leadList?.map((item) => item?.period) ||
                          [], // Use full period otherwise
                  },

                  colors: ["#3900DB", "#F6D3FF"],
                  stroke: {
                    show: true,
                    width: 2,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "80%",
                      borderRadius: 5,
                      borderRadiusApplication: "end",
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                  },
                }}
                series={[
                  {
                    name: "Leads",
                    data:
                      overViewData?.leadList?.map((item) => item?.lead) || [],
                  },
                ]}
                type="bar"
                height={300}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    ROI (Revenue vs Marketing Budget)
                  </Typography>
                  <Typography className="custom-text1">
                    {/* 43% vs.  */}
                    {searchValue}
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "line",
                    toolbar: { show: false },
                  },
                  stroke: {
                    width: [3, 0], // Line width for revenue and no stroke for bar
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "40%", // Adjust bar width
                    },
                  },
                  markers: {
                    size: 5, // Ensures points are visible
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  xaxis: {
                    categories: (
                      overViewData?.ROIRevenueVsMarketingBudget || []
                    ).map((item) => item?.month || ""),
                  },

                  legend: {
                    position: "top",
                    horizontalAlign: "right", // Align legend to the right
                  },
                  colors: ["#3900DB", "#69AEEA"],
                }}
                series={[
                  {
                    name: "Revenue",
                    data: (overViewData?.ROIRevenueVsMarketingBudget || []).map(
                      (item) =>
                        isNaN(parseInt(item?.revenue))
                          ? 0
                          : parseInt(item?.revenue)
                    ),
                    type: "line",
                  },
                  {
                    name: "Marketing Budget",
                    data: (overViewData?.ROIRevenueVsMarketingBudget || []).map(
                      (item) =>
                        isNaN(parseInt(item?.marketing_budget))
                          ? 0
                          : parseInt(item?.marketing_budget)
                    ),
                    type: "bar",
                  },
                ]}
                type="line"
                height={305}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    Active Deals by lead status
                  </Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "radialBar", // Set to radialBar chart type
                    height: 390,
                  },
                  labels:
                    overViewData?.activeDealsByLeadStatus?.leadsByStatus?.map(
                      (item) => item?.lead_status
                    ) || [], // Labels for each radial segment
                  dataLabels: {
                    enabled: true, // Enable data labels
                    style: {
                      fontSize: "18px",
                      fontWeight: 600,
                      colors: ["#3900DB"], // Custom color for the data labels
                    },
                    formatter: (val, opts) => {
                      const totalActiveLead =
                        overViewData?.activeDealsByLeadStatus?.totalActiveLead;
                      return `${totalActiveLead} Active Leads`;
                    },
                  },
                  plotOptions: {
                    radialBar: {
                      offsetY: 0,
                      startAngle: 0, // Start angle at -90 degrees (top of the circle)
                      endAngle: 270, // End angle at 90 degrees (bottom of the circle)
                      hollow: {
                        margin: 5,
                        size: "30%",
                        background: "transparent",
                        image: undefined,
                      },
                      dataLabels: {
                        show: true,
                        name: {
                          show: true, // Show the label name
                        },
                        value: {
                          show: true, // Show the value for each segment
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#72779E", // Customize the value color
                        },
                      },
                      barLabels: {
                        enabled: true,
                        useSeriesColors: false,
                        offsetX: -8,
                        fontSize: "12px",
                        fontFamily: "Arial, sans-serif",
                        fontWeight: 400,
                        color: "#72779E",
                        formatter: function (seriesName, opts) {
                          return `${seriesName} (${
                            opts.w.globals.series[opts.seriesIndex]
                          })`;
                        },
                        style: {
                          colors: ["#72779E"],
                        },
                      },

                      track: {
                        background: "#e5e5e5",
                        strokeWidth: "97%",
                      },
                    },
                  },
                  colors: [
                    "#3900DB",
                    "#F39C12",
                    "#CA00DB",
                    "#5DADE2",
                    "#CB4335",
                    "#DC7633",
                    "#525252",
                    "#03A113",
                    "#F4D03F",
                    "#AF7AC5",
                    "#76D7C4",
                  ], // Customize segment colors
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        legend: {
                          show: false,
                        },
                      },
                    },
                  ],
                }}
                series={
                  overViewData?.activeDealsByLeadStatus?.leadsByStatus?.map(
                    (item) => parseInt(item?.count, 10) || 0
                  ) || [] // Data for each radial segment (each segment represents count)
                }
                type="radialBar"
                height={315} // Set the chart height
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>Leads Source</Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories:
                      searchValue === "Thismonth"
                        ? overViewData?.leadsSource?.map(
                            (item) =>
                              dayjs(item?.period, "YYYY-MM-DD").format("DD")
                          ) || [] // Return formatted days (DD) for the current month
                        :  overViewData?.leadsSource?.map(
                            (item) => item?.period
                          ) || [], // Return full periods if not "This month"
                  },

                  colors: ["#3900DB", "#2781FF","#8FB4E8"],
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "80%",
                      borderRadius: 2,
                      borderRadiusApplication: "end",
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                  },
                }}
                series={[
                  {
                    name: "Google",
                    data:
                      overViewData?.leadsSource?.map(
                        (item) => item?.source?.google
                      ) || [],
                  },
                  {
                    name: "Meta",
                    data:
                    overViewData?.leadsSource?.map(
                      (item) => item?.source?.meta
                    ) || [],
                  },
                  {
                    name: "Others",
                    data:
                    overViewData?.leadsSource?.map(
                      (item) => item?.source?.others
                    ) || [],
                  },
                ]}
                type="bar"
                height={300}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    Top Treatments
                  </Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
            >
              <List
                style={{ height: "35vh", overflow: "auto" }}
                dataSource={overViewData?.topTreatments || []}
                renderItem={(item, index) => (
                  <List.Item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography.Text>
                        {index + 1} : {item?.treatment}
                      </Typography.Text>
                      <Typography.Text
                        className="custom-text1"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setisToptreatmentLeadListModal(true);
                          setselectedTreatmentName(item?.treatment);
                        }}
                        aria-label={`View details for ${item?.treatment}`}
                      >
                        {item?.count} Leads
                        {/* <FaArrowRightLong style={{ marginLeft: 10 }} /> */}
                      </Typography.Text>
                    </div>
                  </List.Item>
                )}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>Conversion</Typography>
                  <Typography className="custom-text1">
                    {isNaN(
                      (overViewData?.leadFormsStatusCount?.filter(
                        (item) => item?.form_status === "Form Abandoned"
                      )?.[0]?.count /
                        (overViewData?.leadFormsStatusCount || []).reduce(
                          (sum, item) => sum + (parseInt(item?.count) || 0),
                          0
                        )) *
                        100
                    )
                      ? "0%"
                      : `${(
                          (overViewData?.leadFormsStatusCount?.filter(
                            (item) => item?.form_status === "Form Abandoned"
                          )?.[0]?.count /
                            (overViewData?.leadFormsStatusCount || []).reduce(
                              (sum, item) => sum + (parseInt(item?.count) || 0),
                              0
                            )) *
                          100
                        ).toFixed(2)} %`}
                    of your leads abandoned forms
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  labels: ["Leads", "Abandoned Forms"],
                  colors: ["#3900DB", "#D5C9F9"],
                  legend: {
                    position: "bottom",
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        size: "65%",
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            label: "Total Leads",
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#3900DB",
                            formatter: () => {
                              const total = (
                                overViewData?.leadFormsStatusCount || []
                              )?.reduce(
                                (sum, item) =>
                                  sum + (parseInt(item?.count) || 0),
                                0
                              );
                              return `${total}`;
                            },
                          },
                        },
                      },
                    },
                  },
                }}
                series={
                  overViewData?.leadFormsStatusCount?.map(
                    (item) => parseInt(item?.count, 10) || 0
                  ) || []
                }
                type="donut"
                height={280}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ marginLeft: 10 }}>
              Confirm Budget Update
            </Typography>
          </div>
        }
        visible={visibleMarketingBudgetConfirmationModal}
        footer={
          <>
            <Divider style={{ margin: 5 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 10,
              }}
            >
              <Space>
                <Button
                  onClick={() => {
                    setvisibleMarketingBudgetConfirmationModal(false);
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => {
                    createOrUpdateMonthlyClinicBudget();
                  }}
                  loading={buttonLoader}
                >
                  Update Budget
                </Button>
              </Space>
            </div>
          </>
        }
        closable={false}
        width={400}
        className="custom-modal"
      >
        <div style={{ padding: 10 }}>
          <Typography>
            Your ROI will be recalculated based on the new budget
            <span style={{ fontWeight: 600 }}>
              {" "}
              $ {marketingBudgetForUpdate}
            </span>{" "}
            Do you want to continue?
          </Typography>
        </div>
      </Modal>
    </div>
  );
};

export default OverviewReport;
