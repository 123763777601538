import React from "react";
import { Row, Col, Card, Typography, Avatar, Empty, Divider } from "antd";
import Chart from "react-apexcharts";
import {
  ConfirmedAppointmentSVG,
  NoShowRateSVG,
  NotConfirmAppointmentSVG,
  TotalAppointmentSVG,
} from "../../Common/SettingSidebarIconsSvg";
import dayjs from "dayjs";

const AppointmentsReport = ({
  getreportApointment,
  reportApointmentData,
  searchValue,
  loginUserDetails,
  openNotificationWithIcon,
  AppointmentsReportcontentRef,
}) => {
  return (
    <div style={{ maxHeight: "80vh", overflow: "auto", padding: 20 }}>
      {/* Top Metrics */}
      <div ref={AppointmentsReportcontentRef}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card className="custom-report-card ">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={45}
                    style={{ background: "#EFEEFF" }}
                    icon={<TotalAppointmentSVG />}
                  />
                </Col>
                <Col>
                  <Typography>
                    {reportApointmentData?.totalAppointment || 0}
                  </Typography>
                  <Typography className="custom-text1">
                    Total Appointments
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card ">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={45}
                    style={{ background: "#EFEEFF" }}
                    icon={<ConfirmedAppointmentSVG />}
                  />
                </Col>
                <Col>
                  <Typography>
                    {reportApointmentData?.totalAppointmentConfirmed || 0}
                  </Typography>
                  <Typography className="custom-text1">
                    Confirmed Appointments
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card ">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={45}
                    style={{ background: "#EFEEFF" }}
                    icon={<NotConfirmAppointmentSVG />}
                  />
                </Col>
                <Col>
                  <Typography>
                    {reportApointmentData?.totalAppointmentNotConfirmed || 0}
                  </Typography>
                  <Typography className="custom-text1">
                    Not confirmed Appointments
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card ">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={45}
                    style={{ background: "#EFEEFF" }}
                    icon={<NoShowRateSVG />}
                  />
                </Col>
                <Col>
                  <Typography>
                    {reportApointmentData?.totalNoShow || 0}
                  </Typography>
                  <Typography className="custom-text1">No Show Rate</Typography>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* Main Charts */}
        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    {isNaN(
                      (reportApointmentData?.appointmentsBookedAndClosed
                        ?.totalClosed /
                        reportApointmentData?.appointmentsBookedAndClosed
                          ?.totalLead) *
                        100
                    )
                      ? "0%"
                      : `${(
                          (reportApointmentData?.appointmentsBookedAndClosed
                            ?.totalClosed /
                            reportApointmentData?.appointmentsBookedAndClosed
                              ?.totalLead) *
                          100
                        ).toFixed(2)} %`}{" "}
                    Conversion Rate
                  </Typography>
                  <Typography className="custom-text1">
                    From Appointments to Closed
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    toolbar: {
                      show: false, // Hide the toolbar
                    },
                  },
                  xaxis: {
                    categories:
                      searchValue === "Thismonth"
                        ? reportApointmentData?.appointmentsBookedAndClosed?.bookedAppointments?.map(
                            (item) =>
                              dayjs(item?.period, "YYYY-MM-DD")?.format("DD")
                          ) || []
                        : reportApointmentData?.appointmentsBookedAndClosed?.bookedAppointments?.map(
                            (item) => item?.period
                          ) || [],
                  },
                  colors: ["#3900DB", "#97FFC6"],
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "55%",
                      borderRadius: 5,
                      borderRadiusApplication: "end", // Apply border radius to the bar ends
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                  },
                }}
                series={[
                  {
                    name: "Appointments booked",
                    data:
                      reportApointmentData?.appointmentsBookedAndClosed?.bookedAppointments?.map(
                        (item) => item?.appointment
                      ) || [],
                  },
                  {
                    name: "Closed",
                    data:
                      reportApointmentData?.appointmentsBookedAndClosed?.closedAppointments?.map(
                        (item) => item?.appointment
                      ) || [],
                  },
                ]}
                type="bar"
                height={300}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    Appointments Status
                  </Typography>
                  <Typography className="custom-text1">This Month</Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    height: 300,
                    toolbar: { show: false },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true, // Horizontal bars for funnel-like effect
                      barHeight: "80%", // Maintain funnel shape
                      isFunnel: true, // Enables funnel styling in ApexCharts
                      distributed: true, // Different colors for each bar
                    },
                  },
                  colors: ["#3900DB", "#2781FF", "#8FB4E8", "#D5C9F9"], // Custom colors
                  dataLabels: {
                    enabled: true,
                    style: {
                      fontSize: "12px",
                      fontWeight: 600,
                    },
                    formatter: (val, { dataPointIndex }) => {
                      const labels = [
                        "Confirmed",
                        "Not Confirmed",
                        "Rescheduled",
                        "No Show",
                      ];
                      return `${labels[dataPointIndex]}: ${val}`;
                    },
                  },
                  xaxis: {
                    categories: [
                      "Confirmed",
                      "Not Confirmed",
                      "Rescheduled",
                      "No Show",
                    ],
                  },
                  legend: {
                    show: false, // Hide legend since labels are in bars
                  },
                }}
                series={[
                  {
                    name: "Appointments Funnel",
                    data: [
                      reportApointmentData?.appointmentsStatus?.confirmed || 0,
                      reportApointmentData?.appointmentsStatus?.notConfirmed ||
                        0,
                      reportApointmentData?.appointmentsStatus?.reschedule || 0,
                      reportApointmentData?.appointmentsStatus?.noShow || 0,
                    ],
                  },
                ]}
                type="bar"
                height={300}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={8}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    Appointment Metrics Overview
                  </Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
              style={{ height: 420 }}
            >
              <Row>
                <Col span={18}>
                  <Typography className="custom-text1">
                    Conversion Rate
                  </Typography>
                </Col>
                <Col span={6}>
                  <Typography>
                    {(
                      ((reportApointmentData?.appointmentMetricsOverview
                        ?.closedLeadse || 0) /
                        (reportApointmentData?.appointmentMetricsOverview
                          ?.totalAppointments || 1)) *
                      100
                    ).toFixed(2)}{" "}
                    %
                  </Typography>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={18}>
                  <Typography className="custom-text1">No Show Rate</Typography>
                </Col>
                <Col span={6}>
                  <Typography>
                    {(
                      ((reportApointmentData?.appointmentMetricsOverview
                        ?.totalNoShow || 0) /
                        (reportApointmentData?.appointmentMetricsOverview
                          ?.totalAppointmentsConfirmed || 1)) *
                      100
                    ).toFixed(2)}{" "}
                    %
                  </Typography>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={18}>
                  <Typography className="custom-text1">
                    Average Lead time Booking
                  </Typography>
                </Col>
                <Col span={6}>
                  <Typography>
                    {reportApointmentData?.appointmentMetricsOverview
                      ?.totalAppointments > 0
                      ? (
                          ((reportApointmentData?.appointmentMetricsOverview
                            ?.closedLeads || 0) -
                            (reportApointmentData?.appointmentMetricsOverview
                              ?.totalAppointmentsConfirmed || 1)) /
                          reportApointmentData?.appointmentMetricsOverview
                            ?.totalAppointments
                        ).toFixed(2)
                      : "0.00"}
                    %
                  </Typography>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={18}>
                  <Typography className="custom-text1">
                    Revenue Earned
                  </Typography>
                </Col>
                <Col span={6}>
                  <Typography>
                    $
                    {(reportApointmentData?.appointmentMetricsOverview
                      ?.totalRevenue[0]?.totalRevenue || 0) -
                      (reportApointmentData?.appointmentMetricsOverview
                        ?.totalAppointmentsConfirmed || 1)}
                  </Typography>
                </Col>
              </Row>
              <Divider />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    Appointment status overview
                  </Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    toolbar: {
                      show: false, // Hide the toolbar
                    },
                  },
                  xaxis: {
                    categories:
                      searchValue === "Thismonth"
                        ? reportApointmentData?.appointmentStatusOverview?.map(
                            (item) =>
                              dayjs(item?.period, "YYYY-MM-DD")?.format("DD")
                          ) || []
                        : reportApointmentData?.appointmentStatusOverview?.map(
                            (item) => item?.period
                          ) || [],
                  },
                  colors: ["#3900DB", "#2781FF", "#D5C9F9"],
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "55%",
                      borderRadius: 5,
                      borderRadiusApplication: "end",
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                  },
                }}
                series={[
                  {
                    name: "booked",
                    data:
                      reportApointmentData?.appointmentStatusOverview?.map(
                        (item) => item?.booked
                      ) || [],
                  },
                  {
                    name: "No Show",
                    data:
                      reportApointmentData?.appointmentStatusOverview?.map(
                        (item) => item?.noShow
                      ) || [],
                  },
                  {
                    name: "cancelled",
                    data:
                      reportApointmentData?.appointmentStatusOverview?.map(
                        (item) => item?.cancelled
                      ) || [],
                  },
                ]}
                type="bar"
                height={300}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    Lead Source Performance
                  </Typography>
                  <Typography className="custom-text1">
                    Appointment booking channels.
                  </Typography>
                </>
              }
            >
              {Object.keys(
                reportApointmentData?.leadSourcePerformanceAppointments || {}
              ).length === 0 ? (
                <div style={{ height: 315 }}>
                  <Empty />
                </div>
              ) : (
                <Chart
                  options={{
                    labels:
                      Object.keys(
                        reportApointmentData?.leadSourcePerformanceAppointments ||
                          {}
                      ) || [],
                    dataLabels: {
                      enabled: false,
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: "65%",
                          labels: {
                            show: true,
                            total: {
                              show: true,
                              label: "Sources",
                              fontSize: "20px",
                              fontWeight: 600,
                              color: "#3900DB",
                              formatter: (w) => {
                                return Object.keys(
                                  reportApointmentData?.leadSourcePerformanceAppointments ||
                                    {}
                                )?.length;
                              },
                            },
                          },
                        },
                      },
                    },
                    colors: ["#3900DB", "#2781FF", "#8FB4E8"],
                    legend: {
                      position: "bottom",
                    },
                  }}
                  series={
                    Object.values(
                      reportApointmentData?.leadSourcePerformanceAppointments ||
                        {}
                    ) || []
                  }
                  type="donut"
                  height={315}
                />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AppointmentsReport;
