import "./App.css";
import AuthRoute from "./Routes/AuthRoute";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Suspense } from "react";
import { Spin, Row, Col } from "antd";

import Test from "./Common/Test";

function App() {
  return (
    <>
      {process.env.REACT_APP_NODE_ENV === "production" ? (
        <Test />
      ) : (
        <Suspense
          fallback={
            <Row
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </Row>
          }
        >
          <AuthRoute />
        </Suspense>
      )}
    </>
  );
}

export default App;
