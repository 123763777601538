import { Card, Typography } from "antd";
import React, { useState, useEffect, useRef } from "react";

const LoadMoreOnScroll = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card style={{ textAlign: "center", padding: "20px", maxWidth: "400px" }}>
        <Typography level={3}>Currently Conducting Data Maintenance</Typography>
        <Typography> Please use old crm until further notice.</Typography>
      </Card>
    </div>
  );
};

export default LoadMoreOnScroll;
