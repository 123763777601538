// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/sidebar.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container-left {
  display: flex;
  justify-content: center;
  align-items: center;

}
.container-right{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: round;
  min-height: 100vh;
}
.logo{
  display: flex;
  justify-content: center;
}
@media (max-width: 576px) {
  .login-container {
    padding: 10px;
  }
  .login-container h2 {
    font-size: 24px;
  }
  .login-container p {
    font-size: 14px;
  }
}

.signup-container-left {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/Routes/Auth.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;AAErB;AACA;EACE,yDAA8C;EAC9C,wBAAwB;EACxB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".login-container-left {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n}\n.container-right{\n  background-image: url(\"../assets/sidebar.jpg\");\n  background-repeat: round;\n  min-height: 100vh;\n}\n.logo{\n  display: flex;\n  justify-content: center;\n}\n@media (max-width: 576px) {\n  .login-container {\n    padding: 10px;\n  }\n  .login-container h2 {\n    font-size: 24px;\n  }\n  .login-container p {\n    font-size: 14px;\n  }\n}\n\n.signup-container-left {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
