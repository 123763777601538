import React from "react";
import { Row, Col, Card, Typography, Avatar, Empty } from "antd";
import Chart from "react-apexcharts";
import {
  ClosedLeadSVG,
  LeadInProgressSVG,
  LostLeadSVG,
  UserReportSVG,
} from "../../Common/SettingSidebarIconsSvg";
import dayjs from "dayjs";

const LeadsReport = ({
  getreportLead,
  reportLeadData,
  searchValue,
  loginUserDetails,
  openNotificationWithIcon,
  LeadsReportcontentRef,
}) => {
  return (
    <div style={{ maxHeight: "80vh", overflow: "auto", padding: 20 }}>
      {/* Top Metrics */}
      <div ref={LeadsReportcontentRef}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card className="custom-report-card ">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={45}
                    style={{ background: "#EFEEFF" }}
                    icon={<UserReportSVG />}
                  />
                </Col>
                <Col>
                  <Typography>{reportLeadData?.totalLead}</Typography>
                  <Typography className="custom-text1">Total Leads</Typography>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card ">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={45}
                    style={{ background: "#EFEEFF" }}
                    icon={<LeadInProgressSVG />}
                  />
                </Col>
                <Col>
                  <Typography>{reportLeadData?.totalLeadInProgress}</Typography>
                  <Typography className="custom-text1">
                    Leads in Progress
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card ">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={45}
                    style={{ background: "#EFEEFF" }}
                    icon={<ClosedLeadSVG />}
                  />
                </Col>
                <Col>
                  <Typography>{reportLeadData?.totalCloseLead}</Typography>
                  <Typography className="custom-text1">Closed Leads</Typography>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="custom-report-card ">
              <Row gutter={[6, 6]}>
                <Col>
                  <Avatar
                    size={45}
                    style={{ background: "#EFEEFF" }}
                    icon={<LostLeadSVG />}
                  />
                </Col>
                <Col>
                  <Typography>{reportLeadData?.totalLostLeads}</Typography>
                  <Typography className="custom-text1">Lost Leads</Typography>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* Main Charts */}
        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>Leads Funnel</Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
            >
              {reportLeadData?.totalLead ||
              reportLeadData?.totalLeadInProgress ||
              reportLeadData?.totalCloseLead ||
              reportLeadData?.totalLostLeads ? (
                <Chart
                  options={{
                    chart: {
                      type: "bar",
                      height: 315,
                      toolbar: { show: false },
                    },
                    plotOptions: {
                      bar: {
                        horizontal: true,
                        barHeight: "80%",
                        isFunnel: true,
                        distributed: true,
                      },
                    },
                    colors: ["#3900DB", "#2781FF", "#69AEEA", "#8FE8C0"], // Ensure there are enough colors for all categories
                    dataLabels: {
                      enabled: true,
                      style: {
                        fontSize: "12px",
                        fontWeight: 600,
                      },
                      formatter: (val, { dataPointIndex }) => {
                        const labels = [
                          "Total Leads",
                          "Leads in Progress",
                          "Closed Leads",
                          "Lost Leads",
                        ];
                        const label = labels[dataPointIndex] || "Unknown";
                        return `${label}: ${val}`;
                      },
                    },
                    xaxis: {
                      categories: [
                        "Total Leads",
                        "Leads in Progress",
                        "Closed Leads",
                        "Lost Leads",
                      ],
                    },
                    legend: {
                      show: false,
                    },
                  }}
                  series={[
                    {
                      name: "Leads Funnel",
                      data: [
                        reportLeadData?.totalLead ?? 0,
                        reportLeadData?.totalLeadInProgress ?? 0,
                        reportLeadData?.totalCloseLead ?? 0,
                        reportLeadData?.totalLostLeads ?? 0,
                      ],
                    },
                  ]}
                  type="bar"
                  height={315}
                />
              ) : (
                <div
                  style={{
                    height: 315,
                  }}
                >
                  <Empty />
                </div>
              )}
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    Leads By Status
                  </Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  labels:
                    reportLeadData?.leadsByStatus?.leadsByStatus?.map(
                      (item) => item?.lead_status
                    ) || [],
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    radialBar: {
                      offsetY: 0,
                      startAngle: 0, 
                      endAngle: 270, 
                      hollow: {
                        margin: 5,
                        size: "30%",
                        background: "transparent",
                        image: undefined,
                      },
                      dataLabels: {
                        show: true,
                        name: {
                          show: true, 
                        },
                        value: {
                          show: true, 
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#72779E", 
                        },
                      },
                      barLabels: {
                        enabled: true,
                        useSeriesColors: false,
                        offsetX: -8,
                        fontSize: "12px",
                        fontFamily: "Arial, sans-serif",
                        fontWeight: 400,
                        color: "#72779E",
                        formatter: function (seriesName, opts) {
                          return `${seriesName} (${
                            opts.w.globals.series[opts.seriesIndex]
                          })`;
                        },
                        style: {
                          colors: ["#72779E"],
                        },
                      },

                      track: {
                        background: "#e5e5e5",
                        strokeWidth: "97%",
                      },
                    },
                  },
                  colors: [
                    "#3900DB",
                    "#F39C12",
                    "#CA00DB",
                    "#5DADE2",
                    "#CB4335",
                    "#DC7633",
                    "#525252",
                    "#03A113",
                    "#F4D03F",
                    "#AF7AC5",
                    "#76D7C4",
                  ],
                }}
                series={
                  reportLeadData?.leadsByStatus?.leadsByStatus?.map(
                    (item) => parseInt(item?.count, 10) || 0
                  ) || []
                }
                type="radialBar"
                height={315}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>Leads Source</Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories:
                      searchValue === "Thismonth"
                        ? reportLeadData?.leadsSource?.map((item) =>
                            dayjs(item?.period, "YYYY-MM-DD").format("DD")
                          ) || [] // Return formatted days (DD) for the current month
                        : reportLeadData?.leadsSource?.map(
                            (item) => item?.period
                          ) || [], // Return full periods if not "This month"
                  },

                  colors: ["#3900DB", "#2781FF", "#8FB4E8"],
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "80%",
                      borderRadius: 2,
                      borderRadiusApplication: "end",
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                  },
                }}
                series={[
                  {
                    name: "Google",
                    data:
                      reportLeadData?.leadsSource?.map(
                        (item) => item?.source?.google
                      ) || [],
                  },
                  {
                    name: "Meta",
                    data:
                      reportLeadData?.leadsSource?.map(
                        (item) => item?.source?.meta
                      ) || [],
                  },
                  {
                    name: "Others",
                    data:
                      reportLeadData?.leadsSource?.map(
                        (item) => item?.source?.others
                      ) || [],
                  },
                ]}
                type="bar"
                height={300}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <>
                  <Typography style={{ fontSize: 15 }}>
                    Credit Score and Finance Status
                  </Typography>
                  <Typography className="custom-text1">
                    {searchValue}
                  </Typography>
                </>
              }
            >
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    stacked: true,
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories: [
                      "Above 650 ",
                      "Below 650",
                      "⁠Financing yes",
                      "⁠Financing no",
                    ],
                  },
                  colors: ["#3900DB", "#6EE2A2"],
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "55%",
                      borderRadius: 5,
                      borderRadiusApplication: "end",
                      dataLabels: {
                        total: {
                          enabled: false,
                          style: {
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#333",
                          },
                        },
                      },
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                  },
                }}
                series={[
                  {
                    name: "unqualified",
                    data: [
                      reportLeadData?.leadCreditScoreReport
                        ?.totalLeadsAbove650List?.notVerified,
                      reportLeadData?.leadCreditScoreReport
                        ?.totalLeadsBelow650List?.notVerified,
                      reportLeadData?.leadCreditScoreReport
                        ?.totalLeadsFinanceYes?.notVerified,
                      reportLeadData?.leadCreditScoreReport?.totalLeadsFinanceNo
                        ?.notVerified,
                    ],
                  },
                  {
                    name: "qualified",
                    data: [
                      reportLeadData?.leadCreditScoreReport
                        ?.totalLeadsAbove650List?.verified,
                      reportLeadData?.leadCreditScoreReport
                        ?.totalLeadsBelow650List?.verified,
                      reportLeadData?.leadCreditScoreReport
                        ?.totalLeadsFinanceYes?.verified,
                      reportLeadData?.leadCreditScoreReport?.totalLeadsFinanceNo
                        ?.verified,
                    ],
                  },
                ]}
                type="bar" // Stacked column chart
                height={300} // Set chart height
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LeadsReport;
