/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  Empty,
  Menu,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { getInitials } from "../../Common/ReturnColumnValue";
import { IoChevronBackSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import axios from "axios";

export const ToptreatmentLeadList = ({
  isToptreatmentLeadListModal,
  setisToptreatmentLeadListModal,
  selectedTreatmentName,
  setselectedTreatmentName,
  setselectedItemDetails,
  setisLeadsDetailsModalVisible,
  searchValue,
  loginUserDetails,
}) => {
  const [loadingleadsList, setloadingleadsList] = useState(false);
  const [leadsList, setleadsList] = useState([]);

  const handleCancel = () => {
    setselectedTreatmentName("");
    setisToptreatmentLeadListModal(false);
  };

  const handleGetLeadListAccodingToSelectedTreatmentName = async () => {
    setloadingleadsList(true);
    const token = localStorage.getItem("authToken");
    let data = {
      clinic_id: loginUserDetails?.userClinicRoles?.[0]?.clinic_id,
      search: searchValue,
      searchType: "datetime",
      field_name: "treatment",
      field_value: selectedTreatmentName || "",
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/getDataAccordingToFieldValue`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setloadingleadsList(false);

        setleadsList(res?.data?.leadDetails || []);
      })
      .catch((err) => {
        console.log(err);
        setloadingleadsList(false);
      });
    setloadingleadsList(false);
  };

  useEffect(() => {
    handleGetLeadListAccodingToSelectedTreatmentName();
  }, [selectedTreatmentName]);

  return (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button onClick={handleCancel} icon={<IoChevronBackSharp />}></Button>
          <Typography style={{ marginLeft: 10 }}>Leads</Typography>
        </div>
      }
      visible={isToptreatmentLeadListModal}
      footer={null}
      closable={false}
      width={300}
      className="custom-modal-lead-details "
    >
      <Row style={{ maxHeight: "40vh", overflow: "auto" }}>
        <Menu style={{ width: "100%", border: "none" }}>
          {loadingleadsList ? (
            <Menu.Item key="loading" disabled>
              <Spin style={{ marginRight: 10 }} size="small" /> Loading...
            </Menu.Item>
          ) : (
            <>
              {leadsList?.length > 0 ? (
                leadsList?.map((lead) => (
                  <Menu.Item
                    key={lead?.id}
                    onClick={() => {
                      setselectedItemDetails(lead);
                      setisLeadsDetailsModalVisible(true);
                    }}
                  >
                    <Space>
                      <Avatar
                        size={25}
                        style={{
                          backgroundColor: lead?.avatar_color,
                          fontSize: 14,
                          marginRight: 5,
                        }}
                      >
                        {lead?.first_name && lead?.last_name
                          ? getInitials(
                              lead?.first_name + " " + lead?.last_name
                            )
                          : lead?.phone_number}
                      </Avatar>
                      <Typography>
                        {lead?.first_name && lead?.last_name
                          ? lead?.first_name + " " + lead?.last_name
                          : lead?.phone_number}
                      </Typography>
                    </Space>
                  </Menu.Item>
                ))
              ) : (
                <Empty />
              )}
            </>
          )}
        </Menu>
      </Row>
    </Modal>
  );
};
